import { BASE_API_URL } from "./constants"


interface IPayload {
    ids: number[] | string[]
    message: string
}

export const sendMessage = async ({ payload, userId }: { payload: IPayload; userId: string }): Promise<any> => {
    return fetch(`${BASE_API_URL}/v1/messages/sendSMSToIdsList`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userId': userId,
        },
        body: JSON.stringify(payload)
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response;
    })
}