import { useQuery } from 'react-query'
import { BASE_API_URL } from './constants'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { jsonToQueryString } from './utils'
import { IFetchServiceRequestsParams } from './interfaces'

const getServiceRequests = async ({ queryKey }: any) => {
  const [_key, { organizationId, userId, status }] = queryKey
  if (organizationId == null) {
    return []
  }

  const queryString = jsonToQueryString({ status });

  return fetch(
    `${BASE_API_URL}/v1/organizations/${organizationId}/service-requests?${queryString}`, {
    headers: {
      'userId': userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }
    return response.json()
  })
}

export function useServiceRequests(organizationId: string | null, status: string,
  { refetchOnWindowFocus = true, onError, onSuccess }: IFetchServiceRequestsParams) {
  const { userId } = useContext(AuthContext)
  return useQuery(
    [`service-requests`, { organizationId, userId, status }],
    getServiceRequests,
    { refetchOnWindowFocus, onError, onSuccess }
  )
}
