import React, { useContext } from 'react'
import { IonRouterOutlet, IonPage } from '@ionic/react'
import { Route } from 'react-router'
import PatientTable from '../patients/PatientTable'
import EmployeeTable from '../employees/EmployeeTable'
import PatientProfile from '../patient/PatientProfile'
import EmployeeProfile from '../employee/EmployeeProfile'
import AddOrganizationDesktop from '../onboarding/AddOrganization'
import PrivacyPolicyDesktop from '../onboarding/PrivacyPolicy'
import { OrgIncomingServiceRequests } from '../org-incoming-service-requests'
import ServiceRequests from '../service-requests'
import Login from '../login/Login'
import EmployeeSignUp from '../onboarding/employees/EmployeeSignUp'
import { AuthContext } from '../contexts/AuthContext'
import PatientMessaging from '../patient-messaging/PatientMessaging'
import ResetPasswordContainer from '../reset-password'


export default function WebRoutes() {
  return (
    <IonPage>
      <IonRouterOutlet id='main-content'>
        <Route path='/login' component={Login} exact />
        <Route path='/signup' component={EmployeeSignUp} exact />
        <Route path='/reset-password' component={ResetPasswordContainer} />
        <Route
          path='/add-organization'
          component={AddOrganizationDesktop}
          exact
        />
        <Route
          path='/privacy-policy'
          component={PrivacyPolicyDesktop}
          exact
        />
        <PrivateRoute exact path='/patients' component={PatientTable} />
        <PrivateRoute exact path='/employees' component={EmployeeTable} />
        <PrivateRoute path='/patients/:id' component={PatientProfile} />
        <PrivateRoute path='/employees/:id' component={EmployeeProfile} />
        <PrivateRoute path='/patient-messaging' component={PatientMessaging} />
        <PrivateRoute path='/incoming-service-requests' component={OrgIncomingServiceRequests} />
        <PrivateRoute exact path={['/service-requests', '/']} component={ServiceRequests} />
      </IonRouterOutlet>
    </IonPage>

  )
}

export function PrivateRoute({ component, ...options }: any) {
  const { isLoggedIn } = useContext(AuthContext)
  const render = isLoggedIn ? component : Login
  return <Route {...options} component={render} />
}

