import React, { useContext } from 'react'
import { IonApp } from '@ionic/react'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css'

import SideMenu from './sidemenu/SideMenu'
// import Header from './header/Header'
import './styles/app.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import { BrowserRouter } from 'react-router-dom'

import WebRoutes from './main/WebRoutes'
import { AuthContext } from './contexts/AuthContext'
import ErrorBoundary from './error-boundary/ErrorBoundary'

/* Theme variables */
// TODO: edit ion varables for hox styling
// import './theme/variables.css'

function App(): JSX.Element {
  const { isLoggedIn } = useContext(AuthContext)
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <IonApp className='app'>
          <ReactNotification />
          {isLoggedIn && <SideMenu />}
          <WebRoutes />
        </IonApp>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
