export enum UserType {
  PATIENT = 'PATIENT',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}

export enum UserTypeFormatted {
  PATIENT = 'Patient',
  ADMIN = 'Admin',
  EMPLOYEE = 'Employee',
}
