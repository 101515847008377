import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from 'react'
import { useHistory } from 'react-router-dom'
import styles from './EmployeeTable.module.scss'
import { IonContent, IonBackdrop, IonSpinner } from '@ionic/react'
import Table from '../table/Table'
import useColumns from './useColumns'
import Page from '../page/Page'
import useEmployees from '../api/useEmployees'
import { getCurrentUser, getCognitoAttribute } from '../authentication/auth'
import { AuthContext } from '../contexts/AuthContext'
import { useMutation } from 'react-query'
import { activateUser, terminateUser } from '../api/updateUserStatus'
import useUser from '../api/useUser'
import { ADMIN, ACTIVE } from '../api/constants'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { ErrorModal } from '../shared/ErrorModal'
import { ErrorMessage } from '../shared/ErrorMessage'
import { Error } from '../shared/utils/constants'

const TABS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INACTIVE: 'Terminated',
}

export default function EmployeeTable() {
  const history = useHistory()
  const [filterInput, setFilterInput] = useState('')
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [error, setError] = useState('')

  const { userId } = useContext(AuthContext)
  const { data: currentUser } = useUser(userId)

  useEffect(() => {
    getCurrentUser((attributes) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const isAdmin = useMemo(() => {
    return currentUser?.status === ACTIVE && currentUser?.userType === ADMIN
  }, [currentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const {
    data: employees,
    isFetching,
    isError,
    refetch,
    isSuccess,
  } = useEmployees(loggedInUserOrgId, {
    onError: (err) => {
      console.log(err.message)
      if (err.message == 403) {
        setError(Error.UNAUTHORIZE)
      }
    },
  })

  const groupBy = (key, items = []) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    )

  // Sort employee list by status
  const sortByStatus = (data = []) => {
    const status = ['PENDING', 'ACTIVE', 'INACTIVE']
    return data.sort(
      (a, b) => status.indexOf(a.status) - status.indexOf(b.status),
    )
  }

  const sections = useMemo(() => {
    return groupBy('status', sortByStatus(employees))
  }, [employees])

  useEffect(() => {
    if (isSuccess && Object.keys(sections).length < 2) {
      setTabIndex(0)
    }
  }, [isSuccess, sections])

  const { mutate: mutateTerminate } = useMutation(terminateUser, {
    onSuccess: () => refetch(),
  })

  const { mutate: mutateActivate } = useMutation(activateUser, {
    onSuccess: () => refetch(),
  })

  const columns = useColumns(userId, mutateActivate, mutateTerminate, isAdmin)

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    setFilterInput(value)
  }, [])

  const onSelectTab = (index) => setTabIndex(index)

  const handleDismiss = useCallback(() => {
    setError('')
    history.goBack()
  }, [setError])

  if (isError && error) {
    return (
      <ErrorModal
        onDismiss={handleDismiss}
        text={error}
      />
    )
  }

  if (isError) {
    return <ErrorMessage page='Employees' />
  }

  if (!employees) {
    return (
      <IonContent>
        <Page name='Employees'>
          <IonSpinner className={styles.loader} />
        </Page>
      </IonContent>
    )
  }

  return (
    <IonContent>
      {isFetching ? <IonBackdrop /> : null}
      <Page name='Employees'>
        <div className={styles.pageWrapper}>
          <div className={styles.tabsContainer}>
            <Tabs selectedIndex={tabIndex} onSelect={onSelectTab}>
              <TabList className={styles.tabList}>
                {Object.keys(sections).map((key) => (
                  <Tab className={styles.tabItem} key={key}>
                    {TABS[key]}
                  </Tab>
                ))}
              </TabList>
              {Object.keys(sections).map((key) => (
                <TabPanel key={key} className={styles.tabsContainer}>
                  <div className={styles.tableWrapper}>
                    <div className={styles.tableTopRow}>
                      <input
                        className={styles.searchBar}
                        value={filterInput}
                        onChange={handleFilterChange}
                        placeholder='Search Employees...'
                      />
                    </div>
                    {!isFetching && (
                      <Table
                        data={sections[key]}
                        columns={columns}
                        onClick={({ row }) => {
                          if (isAdmin) {
                            history.push(`/employees/${row.original.id}`)
                          }
                        }}
                        globalFilterInput={filterInput}
                      />
                    )}
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </Page>
    </IonContent>
  )
}
