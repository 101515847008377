// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_serviceRequestId__NdmsB {\n  color: #367bf5;\n  font-size: 14px;\n  line-height: 20px;\n  text-decoration: underline;\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/org-incoming-service-requests/utils/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAE1B,eAAe,EAAA","sourcesContent":[".serviceRequestId {\n  color: #367bf5;\n  font-size: 14px;\n  line-height: 20px;\n  text-decoration: underline;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceRequestId": "styles_serviceRequestId__NdmsB"
};
export default ___CSS_LOADER_EXPORT___;
