import React from 'react'
import { useFetchNotesFile } from '../../../api/useDownloadFile'
import ProfileIcon from '../../../profile/ProfileIcon'
import { ServiceRequestNote } from '../../models/ServiceRequestNote'
import { formatTimestampToFullDate } from '../../utils/formatTimestamp'
import styles from '../styles.module.scss'

interface INote {
  note: ServiceRequestNote
}

function Note({ note }: INote): JSX.Element {
  const { data: noteFile, refetch: downloadNoteFile } = useFetchNotesFile(
    note.id as string,
    note.fileName
  )

  const renderNotesFile = (name: string) => {
    if (name) {
      return (
        <a
          href={noteFile}
          download
          target='_blank'
          onClick={() => downloadNoteFile()}
          className={styles.fileLink}
        >
          {name}
        </a>
      )
    }
  }

  const noteDetails = () => {
      const details = `${note.createdBy?.firstName} ${note.createdBy?.lastName}`
      const timeStamp = formatTimestampToFullDate(note?.updateTimestamp as string)
      return details + ' ' + timeStamp
  }

  return (
    <div className={styles.comment}>
      <ProfileIcon
        size={'32'}
        userName={note?.createdBy?.firstName + ' ' + note?.createdBy?.lastName}
      />
      <div className={styles.commentBody}>
        <div className={styles.commentText}>
          {note.content} {renderNotesFile(note.fileName)}
        </div>
        {note.createdBy && (
          <div className={styles.author}>{noteDetails()}</div>
        )}
      </div>
    </div>
  )
}

export default Note
