export const BASE_API_URL = process.env.REACT_APP_BASE_URL

export const ORGANIZATION = 'ORGANIZATION'
export const PATIENT = 'PATIENT'
export const EMPLOYEE = 'EMPLOYEE'
export const ADMIN = 'ADMIN';

export const ACTIVE = 'ACTIVE'
export const PENDING = 'PENDING'
export const TERMINATED = 'TERMINATED'
