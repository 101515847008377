// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_error__3lRFT {\n  text-align: center;\n  margin: 50px; }\n", "",{"version":3,"sources":["webpack://src/shared/ErrorMessage/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,YAAY,EAAA","sourcesContent":[".error {\n    text-align: center;\n    margin: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "styles_error__3lRFT"
};
export default ___CSS_LOADER_EXPORT___;
