import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
  IonRouterLink,
} from '@ionic/react'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import HealthOpXLogo from '../public/assets/HealthOpXLogo.png'
import SignInDoctors from '../public/assets/signin-doctors.png'
import { lockClosed, mail } from 'ionicons/icons'
import LoginUser from '../authentication/Login'
import Notices from '../alerts/Notices'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'

type AuthenticationError = {
  code: string
  message: string
  name: string
}

function Login({ history }: RouteComponentProps): JSX.Element {
  const [error, setError] = useState<null | AuthenticationError>(null)
  const [showNotice, setShowNotice] = useState(false)
  const [loading, setLoading] = useState(false)
  const formMethods = useForm({ defaultValues: { email: '', password: '' } })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods

  const onSignInHandler = async (data: { email: string; password: string }) => {
    setLoading(true)
    const { error } = await LoginUser(data.email, data.password)
    if (error == null) {
      history.push('/service-requests')
      window.location.reload()
    } else {
      setShowNotice(true)
      setError(error)
    }
    setLoading(false)
  }

  return (
    <IonContent fullscreen>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonRow>
              <IonImg className='sign-in-img' src={SignInDoctors} />
            </IonRow>
          </IonCol>
          <IonCol style={{ paddingRight: '100px' }}>
            <IonRow>
              <IonCol>
                <div className='ion-text-start'>
                  <IonImg className='logo' src={HealthOpXLogo} />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-text-start'>
                <IonText id='login-subtitle'>
                  Technology connecting healthcare to community.
                </IonText>
              </IonCol>
            </IonRow>
            {error && showNotice && (
              <Notices
                header='Login Failure'
                content={error.message}
                type='ERROR'
                onClose={() => setShowNotice(false)}
              />
            )}
            <IonRow style={{ paddingTop: '100px' }}>
              <IonCol className='ion-text-start'>
                <IonText id='login-title'>Login to HealthOpX</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-text-start'>
                <IonText id='login-text'>Dont't have an account? </IonText>
                <IonRouterLink
                  id='login-text'
                  style={{ color: '#37d6ba' }}
                  routerLink='/signup'
                >
                  Sign Up
                </IonRouterLink>
              </IonCol>
            </IonRow>

            <FormProvider {...formMethods}>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                <IonList>
                  <IonItem>
                    {errors.email ? (
                      <IonLabel position='floating' color='danger'>
                        {errors.email.message}
                      </IonLabel>
                    ) : (
                      <IonLabel position='stacked'>Email</IonLabel>
                    )}
                    <IonInput
                      {...register('email', {
                        required: 'Email is required.',
                      })}
                      placeholder='user@email.com'
                      type='email'
                      name='email'
                    />
                    <IonIcon icon={mail} slot='end' />
                  </IonItem>
                  <IonItem>
                    {errors.password ? (
                      <IonLabel position='floating' color='danger'>
                        {errors.password.message}
                      </IonLabel>
                    ) : (
                      <IonLabel position='stacked'>Password</IonLabel>
                    )}
                    <IonInput
                      {...register('password', {
                        required: 'Password is required.',
                      })}
                      placeholder='********'
                      type='password'
                      name='password'
                    />
                    <IonIcon icon={lockClosed} slot='end' />
                  </IonItem>
                </IonList>
                <IonRow className='ion-text-end ion-padding-end'>
                  <IonCol className='ion-text-start'>
                    <Link to={'/reset-password'}>
                      <IonText id='login-text'>Forgot Password?</IonText>
                    </Link>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-text-end ion-padding-end'>
                  <IonCol className='ion-text-end ion-margin-horizontal'>
                    <IonButton
                      shape='round'
                      fill='outline'
                      expand='full'
                      color='dark'
                      onClick={handleSubmit(onSignInHandler)}
                    >
                      {loading ? (
                        <IonSpinner name='crescent' color='medium' />
                      ) : (
                        'Sign In'
                      )}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </FormProvider>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Login
