import { useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'

import { OrganizationServices } from '../shared/models/OrganizationServices'

const getOrganizationServices = async ({ queryKey }) => {
  const [_key, { organizationId, userId }] = queryKey
  return await fetch(
    `${BASE_API_URL}/v1/organizations/${organizationId}/services`,
    {
      method: 'GET',
      headers: {
        userId: userId,
      },
    },
  ).then((response) => response.json())
}

export default function useOrganizationServices(
  organizationId: number,
): UseQueryResult<OrganizationServices[], unknown> {
  const { userId } = useContext(AuthContext)

  return useQuery(
    [`organization-${organizationId}-services`, { organizationId, userId }],
    getOrganizationServices,
    { enabled: isFinite(organizationId) },
  )
}
