import React, { useState, useCallback } from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import 'react-tabs/style/react-tabs.css'
import Table from '../../../table/Table'
import styles from '../../styles.module.scss'
import { ServiceRequest } from '../../../shared/models/ServiceRequest'
import { useServiceRequestReviewModal } from '../../../shared/ServiceRequestReviewModal'
import { adminTableColumns } from '../../utils/adminTableColumns'
import { useNewServiceRequestModal } from '../../../shared/NewServiceRequest'
import { SuccessModal } from '../../../shared/SuccessModal'
import { useServiceRequests } from '../../../api/useServiceRequests'
import { Error } from '../../../shared/utils/constants'
import { ErrorModal } from '../../../shared/ErrorModal'

interface IProps {
  type: string
  orgId: string
}

const TabContent = ({ type, orgId }: IProps): JSX.Element => {
  const [serviceRequestChosen, setServiceRequestChosen] = useState<
    ServiceRequest | undefined | null
  >(null)
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const [error, setError] = useState('')

  // TODO: add search and filter change funcitonality
  const [filterInput, setFilterInput] = useState('')

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    setFilterInput(value)
  }, [])

  const {
    data: serviceRequests = [],
    isLoading,
    isError,
    refetch: refetchRequestes,
  } = useServiceRequests(orgId, type, 
    { 
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.message === '403') {
          setError(Error.UNAUTHORIZE)
        }
      }
    })

  // New Service Request
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
  } = useNewServiceRequestModal({
    onSuccess: () => setSuccessModalVisible(true),
  })

  const { ServiceRequestReviewModal, isVisible } = useServiceRequestReviewModal(
    {
      serviceRequest: serviceRequestChosen,
      onClose: () => {
        setServiceRequestChosen(null)
        refetchRequestes()
      },
    },
  )

  const handleServiceRequestIdClick = (serviceRequestId: string) => {
    const ticket = (serviceRequests as ServiceRequest[])?.filter(
      (i) => i.id === serviceRequestId,
    )[0]
    setServiceRequestChosen(ticket)
    console.log(ticket)
  }

  const columns = adminTableColumns({
    onServiceRequestIdClick: handleServiceRequestIdClick,
  })

  const handleDismiss = () => {
    setError('')
  }

  if (isError && error) {
    return (
      <ErrorModal
        onDismiss={handleDismiss}
        text={error}
      />
    )
  }

  const renderRequests = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <IonSpinner className={styles.loader} />
        </div>
      )
    }
    if (isError) {
      return (
        <div className={styles.error}>
          Something went wrong. Please try again later.
        </div>
      )
    }
    return (
      <Table
        data={serviceRequests}
        columns={columns}
        globalFilterInput={filterInput}
        initialState={{ sortBy: [{ id: 'id', desc: true }] }}
      />
    )
  }

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableTopRow}>
        <input
          className={styles.searchBar}
          value={filterInput}
          onChange={handleFilterChange}
          placeholder='Search Service Requests...'
        />
        <IonButton
          className={styles.newItemButton}
          onClick={showNewServiceRequest}
        >
          {'+New Patient Referral'}
        </IonButton>
      </div>
      {renderRequests()}
      <NewServiceRequest />
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={() => setSuccessModalVisible(false)}
          text='The request was created, someone from the CBO or Nonprofit should be in 
          contact with the patient soon.'
        />
      )}
      {serviceRequestChosen && isVisible && <ServiceRequestReviewModal />}
    </div>
  )
}

export default TabContent
