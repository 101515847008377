import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL, ORGANIZATION, PATIENT, ACTIVE } from './constants'
import { jsonToQueryString } from './utils'

const getPatients = async ({ queryKey }) => {
  const [_key, { orgId, userId }] = queryKey
  if (orgId == null) {
    return []
  }
  const queryString = jsonToQueryString({
    entityId: orgId,
    entityType: ORGANIZATION,
    role: PATIENT,
    status: ACTIVE,
  })
  return fetch(
    `${BASE_API_URL}/v1/users?${queryString}`, {
    headers: {
      'userId': userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.status)
    }
    return response.json()
  })
}

export default function usePatients(orgId,
  {
    refetchOnWindowFocus = true,
    onSuccess = (res) => {
      // success
    },
    onError = (error) => {
      // error
    }
  }) {
  const { userId } = useContext(AuthContext)
  return useQuery([`patients-${orgId}`, { orgId, userId }], getPatients, { refetchOnWindowFocus, onError, onSuccess })
}
