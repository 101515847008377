import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { BASE_API_URL } from './constants'
import { IFetchServiceRequestsParams } from './interfaces'
import { jsonToQueryString } from './utils'

const getOrgIncomingServiceRequests = async ({ queryKey }: any) => {
  const [_key, , { organizationId, userId, status }] = queryKey
  if (organizationId == null) {
    return []
  }

  const queryString = jsonToQueryString({ status });

  return fetch(
    `${BASE_API_URL}/v1/organizations/${organizationId}/incoming-service-requests?${queryString}`, {
    headers: {
      'userId': userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }
    return response.json()
  })
}

export function useOrgIncomingServiceRequests(organizationId: string, status: string,
  { refetchOnWindowFocus = true, onSuccess, onError }: IFetchServiceRequestsParams) {
  return useQuery(
    getOrgIncomingServiceRequestsQueryKey(organizationId, status),
    getOrgIncomingServiceRequests,
    { refetchOnWindowFocus, onSuccess, onError }
  )
}

export function getOrgIncomingServiceRequestsQueryKey(
  organizationId: string | number, status: string
): any[] {
  const { userId } = useContext(AuthContext)

  return ['service-requests', 'incoming', { organizationId, userId, status }]
}

export function updateOrgIncomingServiceRequestsInCache(
  cachedData: ServiceRequest[] | undefined,
  updated: ServiceRequest,
) {
  if (!cachedData) {
    return cachedData
  }

  return cachedData.map((serviceRequest) =>
    serviceRequest.id === updated.id ? updated : serviceRequest,
  )
}