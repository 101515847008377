import { IonIcon } from '@ionic/react'
import React from 'react'
import styles from '../styles.module.scss'
import AttachFile from './AttachFile'
import { pencil } from 'ionicons/icons'

interface IProps {
    file: File | undefined,
    setFile: (file: File) => void
    onSubmit: () => void
}

const NotesActions = ({ file, setFile, onSubmit }: IProps ) => {
  return (
    <div className={styles.notesActions}>
      <span>{file?.name}</span>
      <div>
        <AttachFile setFile={setFile} />
        <IonIcon
          className={styles.noteIcon}
          icon={pencil}
          slot='end'
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

export default NotesActions
