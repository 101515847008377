import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL, ORGANIZATION, EMPLOYEE, ACTIVE } from './constants'
import { jsonToQueryString } from './utils'

const getEmployees = async ({ queryKey }) => {
  const [_key, { orgId, userId }] = queryKey
  if (orgId == null) {
    return []
  }
  const queryString = jsonToQueryString({
    entityId: orgId,
    entityType: ORGANIZATION,
    role: EMPLOYEE,
  })
  return fetch(
    `${BASE_API_URL}/v1/users?${queryString}`, {
    headers: {
      'userId': userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.status)
    }
    return response.json()
  })
}

export default function useEmployees(orgId, { onSuccess, onError }) {
  const { userId } = useContext(AuthContext)

  return useQuery([`employees-${orgId}`, { orgId, userId }], getEmployees, { onSuccess, onError })
}
