const serviceTypes = {
  FOOD: 'Food Assistance  +',
  TRANSLATION: 'Translation Assistance  +',
  TRANSPORTATION: 'Transportation Assistance  +',
  OTHER: 'Other',
  EMPLOYMENT: 'Employment  +',
}

export function getServiceTypeName(serviceType: string) {
  // @ts-ignore
  return serviceTypes[serviceType] || serviceType
}
