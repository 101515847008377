import classNames from 'classnames'
import * as React from 'react'

import { AvatarStatusProps } from './interfaces'

import styles from './styles.module.scss'

export function AvatarStatus({
  status = 'unknown',
  children,
}: AvatarStatusProps) {
  return (
    <span className={styles.avatarStatusWrapper}>
      {children}
      <span
        className={classNames(
          styles.avatarStatus,
          styles['avatarStatus_' + status],
        )}
      ></span>
    </span>
  )
}
