import React, { useRef, useState } from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonItem,
  IonButton,
  IonText,
  IonList,
  IonLabel,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSpinner,
} from '@ionic/react'
import { useFormContext } from 'react-hook-form'
import { person, lockClosed, arrowBack } from 'ionicons/icons'
import {
  validPhoneNumber,
  validEmail,
  validPassword,
} from '../../validation/SignUpValidation'

function EmployeeStepTwo(props: {
  next: any
  isLoading: boolean
  error?: string
}) {
  const {
    register,
    formState: { errors },
    watch,
    unregister,
  } = useFormContext()
  const password = useRef({})
  password.current = watch('password', '')
  const [confirmPass, setConfirmPass] = useState('')

  return (
    <IonGrid>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              text=''
              icon={arrowBack}
              defaultHref={'/login'}
              className='custom-back-button'
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonRow className='intro-container'>
        <IonCol>
          <IonText className='header-text'>{'Create Your Profile'}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className='description-container'>
        <IonCol>
          <IonText className='description-text'>
            {'We will need to set up and secure your account.'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center'>
          <IonList>
            <IonItem>
              {errors.firstName && (
                <IonLabel position='floating' color='danger'>
                  {errors.firstName.message}
                </IonLabel>
              )}
              <IonInput
                {...register('firstName', {
                  required: 'First Name is required.',
                })}
                placeholder='First Name'
                name='firstName'
              />
              <IonIcon icon={person} slot={'end'} />
            </IonItem>
            <IonItem>
              {errors.lastName && (
                <IonLabel position='floating' color='danger'>
                  {errors.lastName.message}
                </IonLabel>
              )}
              <IonInput
                {...register('lastName', {
                  required: 'Last Name is required.',
                })}
                placeholder='Last Name'
                name='lastName'
              />
              <IonIcon icon={person} slot={'end'} />
            </IonItem>
            <IonItem>
              {errors.phoneNumber && (
                <IonLabel position='floating' color='danger'>
                  {errors.phoneNumber.message}
                </IonLabel>
              )}
              <IonInput
                {...register('phoneNumber', {
                  required: 'Phone Number is required.',
                  validate: (value) =>
                    validPhoneNumber(value) || 'Must be a valid Phone Number.',
                })}
                placeholder='+12223334444'
                name='phoneNumber'
              />
            </IonItem>
            <IonItem>
              {errors.email && (
                <IonLabel position='floating' color='danger'>
                  {errors.email.message}
                </IonLabel>
              )}
              <IonInput
                {...register('email', {
                  required: 'Email is required.',
                  validate: (value) =>
                    validEmail(value) || 'Must be a valid Email.',
                })}
                placeholder='Email'
                type='email'
                name='email'
              />
            </IonItem>
            <IonItem>
              {errors.password && (
                <IonLabel position='floating' color='danger'>
                  {errors.password.message}
                </IonLabel>
              )}
              <IonInput
                {...register('password', {
                  required: 'Password is required.',
                  validate: (value) => {
                    if (!validPassword(value).valid)
                      return validPassword(value).error
                    if (value !== confirmPass) return 'Passwords do not match.'
                  },
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters.',
                  },
                })}
                placeholder='Password'
                type='password'
                name='password'
              />
              <IonIcon icon={lockClosed} slot={'end'} />
            </IonItem>
            <IonItem>
              {errors.confirmPassword && (
                <IonLabel position='floating' color='danger'>
                  Please fill out required fields
                </IonLabel>
              )}
              <IonInput
                placeholder='Confirm Password'
                onIonChange={(e) => setConfirmPass(e.detail.value as string)}
                type='password'
              />
              <IonIcon icon={lockClosed} slot={'end'} />
            </IonItem>
          </IonList>
          {props.error && !props.isLoading && <IonLabel color="danger">{props.error}</IonLabel>}
          <IonButton
            shape='round'
            fill='outline'
            expand='full'
            color='dark'
            className='next-button'
            type='submit'
            onClick={() => {
              unregister('confirmPassword')
              props.next([
                'firstName',
                'lastName',
                'zipcode',
                'phoneNumber',
                'email',
                'password',
              ])
            }}
          >
            {props.isLoading ? <IonSpinner color='dark' /> : `SUBMIT`}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default EmployeeStepTwo
