import { useQuery } from "react-query"
import { BASE_API_URL } from "./constants"

type Extension = 'jpg' | 'jpeg' | 'png' | 'pdf'

const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf'
}

const getMimeType = (filename: string) => {
    const ext = filename.split('.').pop()
    if (ext) {
        return mimeTypes[ext as Extension]
    }
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

async function fetchFile(url: string, name: string) {
    const response = await fetch(url)
    if (!response.ok) {
        throw new Error(response.statusText)
    }
    const binaryData = await response.arrayBuffer();
    const base64 = arrayBufferToBase64(binaryData)
    return `data:${getMimeType(name)};base64,${base64}`;
}

async function fetchSRFile({ queryKey }: any) {
    const [key, { serviceRequestId, name }] = queryKey
    const url = `${BASE_API_URL}/v1/service-requests/${serviceRequestId}/file`
    return fetchFile(url, name)
}

async function fetchNotesFile({ queryKey }: any) {
    const [key, { noteId, name }] = queryKey
    const url = `${BASE_API_URL}/v1/notes/${noteId}/file`
    return fetchFile(url, name)
}

export function useFetchSRFile(serviceRequestId: string, name: string) {
    return useQuery(['service-request-file', { serviceRequestId, name }], fetchSRFile, { enabled: false })
}

export function useFetchNotesFile(noteId: string, name) {
    return useQuery(['service-request-note-file', { noteId, name }], fetchNotesFile, { enabled: false })
}