import React, { useState, useCallback, useMemo } from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import 'react-tabs/style/react-tabs.css'
import {
  getOrgIncomingServiceRequestsQueryKey,
  updateOrgIncomingServiceRequestsInCache,
  useOrgIncomingServiceRequests,
} from '../../../api/useOrgIncomingServiceRequests'
import Table from '../../../table/Table'
import styles from '../../styles.module.scss'
import { ServiceRequest } from '../../../shared/models/ServiceRequest'
import {
  addServiceRequestNoteInCache,
  getServiceRequestNotesQueryKey,
} from '../../../api/useServiceRequestNotes'
import { useServiceRequestReviewModal } from '../../../shared/ServiceRequestReviewModal'
import { tableColumns } from '../../utils/tableColumns'
import { Error } from '../../../shared/utils/constants'
import { ErrorModal } from '../../../shared/ErrorModal'

interface IProps {
  type: string
  showNewServiceRequest: any
  loggedInUserOrgId: string
}

const TabContent = ({
  type,
  showNewServiceRequest,
  loggedInUserOrgId,
}: IProps): JSX.Element => {
  // Filter
  const [filterInput, setFilterInput] = useState('')
  const [error, setError] = useState('')

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    setFilterInput(value)
  }, [])

  const {
    data: serviceRequests = [],
    isError,
    isLoading,
    refetch: refetchRequestes,
  } = useOrgIncomingServiceRequests(loggedInUserOrgId, type, 
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.message === '403') {
          setError(Error.UNAUTHORIZE)
        }
      }
    })

  /// Service Request View
  const [
    serviceRequestChosen,
    setServiceRequestChosen,
  ] = useState<ServiceRequest | null>(null)
  // * Close
  const handleServiceRequestReviewModalClose = useCallback(() => {
    setServiceRequestChosen(null)
    refetchRequestes()
  }, [setServiceRequestChosen])
  // * Open
  const handleServiceRequestIdClick = useCallback(
    (serviceRequestId: number) => {
      setServiceRequestChosen(
        // @ts-ignore
        serviceRequests.find((i) => i.id === serviceRequestId) || null,
      )
    },
    [serviceRequests],
  )
  // * getQueryKeyForServiceRequests
  const getBindedQueryKeyForServiceRequests = useMemo(
    () =>
      getOrgIncomingServiceRequestsQueryKey.bind(
        null,
        loggedInUserOrgId,
        status,
      ),
    [loggedInUserOrgId, status],
  )
  // * getQueryKeyForNotes
  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        serviceRequestChosen?.id || NaN,
      ),
    [serviceRequestChosen?.id],
  )
  const { ServiceRequestReviewModal, isVisible } = useServiceRequestReviewModal(
    {
      serviceRequest: serviceRequestChosen,
      onClose: handleServiceRequestReviewModalClose,

      getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
      addServiceRequestNoteInCache: addServiceRequestNoteInCache,

      getQueryKeyForServiceRequests: getBindedQueryKeyForServiceRequests,
      updateServiceRequestInCache: updateOrgIncomingServiceRequestsInCache,
    },
  )

  const handleDismiss = useCallback(() => {
    setError('')
  }, [setError])

  /// Table content
  const columns = tableColumns({
    onServiceRequestIdClick: handleServiceRequestIdClick,
  })


  if (isError && error) {
    return (
      <ErrorModal
        onDismiss={handleDismiss}
        text={error}
      />
    )
  }

  const renderRequests = () => {
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <IonSpinner className={styles.loader} />
        </div>
      )
    }
    if (isError) {
      return (
        <div className={styles.error}>
          Something went wrong. Please try again later.
        </div>
      )
    }
    return (
      <Table
        data={serviceRequests}
        columns={columns}
        globalFilterInput={filterInput}
        initialState={{ sortBy: [{ id: 'id', desc: true }] }}
      />
    )
  }

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableTopRow}>
        <input
          className={styles.searchBar}
          value={filterInput}
          onChange={handleFilterChange}
          placeholder='Search Service Requests...'
        />
        <IonButton
          className={styles.newItemButton}
          onClick={showNewServiceRequest}
        >
          {'+New Patient Referral'}
        </IonButton>
      </div>
      {renderRequests()}
      {serviceRequestChosen && isVisible && <ServiceRequestReviewModal />}
    </div>
  )
}

export default TabContent
