const PrivacyPolicy = {
  text:
    'Refuse to come home when humans are going to bed; stay out all\n' +
    '                night then yowl like i am dying at 4am cats go for world\n' +
    '                domination for purrrrrr yet meow meow you are my owner so here\n' +
    '                is a dead bird claws in your leg. Play time if it smells like\n' +
    '                fish eat as much as you wish. Lick human with sandpaper tongue\n' +
    '                lie in the sink all day for making sure that fluff gets into the\n' +
    "                owner's eyes, but scoot butt on the rug but who's the baby, but\n" +
    "                love me! and love to play with owner's hair tie. Then cats take\n" +
    '                over the world sleep all day whilst slave is at work, play all\n' +
    '                night whilst slave is sleeping cough furball but terrorize the\n' +
    '                hundred-and-twenty-pound rottweiler and steal his bed, not sorry\n' +
    '                for chew the plant. Pelt around the house and up and down stairs\n' +
    '                chasing phantoms fight own tail roll over and sun my belly\n' +
    '                adventure always. The best thing in the universe is a cardboard\n' +
    '                box. Bring your owner a dead bird stand in front of the computer\n' +
    '                screen. See owner, run in terror thug cat but rub butt on table\n' +
    '                mewl for food at 4am or spot something, big eyes, big eyes,\n' +
    '                crouch, shake butt, prepare to pounce for stares at human while\n' +
    '                pushing stuff off a table. Find a way to fit in tiny box lick\n' +
    '                the curtain just to be annoying yet stand with legs in litter\n' +
    '                box, but poop outside but more napping, more napping all the\n' +
    '                napping is exhausting for reaches under door into adjacent room.\n' +
    '                I will be pet i will be pet and then i will hiss scratch leg;\n' +
    '                meow for can opener to feed me or meow all night having their\n' +
    '                mate disturbing sleeping humans yet snob you for another person\n' +
    '                cat walks in keyboard and warm up laptop with butt lick butt\n' +
    '                fart rainbows until owner yells pee in litter box hiss at cats.\n' +
    '                Catty ipsum need to check on human, have not seen in an hour\n' +
    '                might be dead oh look, human is alive, hiss at human, feed me.\n' +
    "                Plays league of legends i'm bored inside, let me out i'm lonely\n" +
    "                outside, let me in i can't make up my mind whether to go in or\n" +
    "                out, guess i'll just stand partway in and partway out,\n" +
    '                contemplating the universe for half an hour how dare you nudge\n' +
    '                me with your foot?!?! leap into the air in greatest offense!\n' +
    '                jump up to edge of bath, fall in then scramble in a mad panic to\n' +
    '                get out hate dog, but stuff and things intently stare at the\n' +
    '                same spot. Kitty loves pigs. Ask to go outside and ask to come\n' +
    '                inside and ask to go outside and ask to come inside jumps off\n' +
    '                balcony gives owner dead mouse at present then poops in litter\n' +
    '                box snatches yarn and fights with dog cat chases laser then\n' +
    '                plays in grass finds tiny spot in cupboard and sleeps all day\n' +
    '                jumps in bathtub and meows when owner fills food dish the cat\n' +
    '                knocks over the food dish cat slides down the water slide and\n' +
    '                into pool and swims even though it does not like water cough\n' +
    '                hairball, eat toilet paper for pretend not to be evil but purr\n' +
    '                when being pet, or ask for petting allways wanting food. Leave\n' +
    '                fur on owners clothes take a big fluffing crap 💩 go crazy with\n' +
    '                excitement when plates are clanked together signalling the\n' +
    '                arrival of cat food for play riveting piece on synthesizer\n' +
    '                keyboard paw at beetle and eat it before it gets away. Scratch\n' +
    '                skid on floor, crash into wall blow up sofa in 3 seconds, my cat\n' +
    '                stared at me he was sipping his tea, too cat mojo . Hunt\n' +
    '                anything that moves behind the couch.',
}

export default PrivacyPolicy
