import React, { useContext, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { updateUserDetails } from '../../api/updateUserDetails'
import useLanguages from '../../api/useLanguages'
import useUser from '../../api/useUser'
import { AuthContext } from '../../contexts/AuthContext'
import { User } from '../models/User'
import { EditUserModal } from './EditUserModal'

export function useEditUserModal(props: {
  userId: string
  onSuccess: () => void
  onError: () => void
  onCancel: () => void
}) {
  const { userId } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { data: user } = useUser(props.userId)
  const { data: languages } = useLanguages()

  // Visibility
  const [isModalVisible, setModalIsVisible] = useState(false)
  useEffect(() => {
    if (!isModalVisible && user) {
      setModalIsVisible(true)
    }
  }, [user, isModalVisible])

  // Submitting
  const { mutate: mutateUpdateUserDetails } = useMutation(updateUserDetails, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(`user-${props.userId}`)
    },
  })

  updateUserDetails
  const handleUserSubmit = useCallback(
    (user: User) => {
      console.log('user edited:', user)
      mutateUpdateUserDetails({ user, userId }, {
        onSuccess: props.onSuccess,
      })
      setModalIsVisible(false)
    },
    [props.userId, props.onSuccess, setModalIsVisible],
  )
  const handleModalClose = useCallback(() => {
    setModalIsVisible(false)
    props.onCancel()
  }, [])

  const component = useMemo(
    () => () =>
      (
        <>
          {user && languages && isModalVisible && (
            <EditUserModal
              user={user}
              languages={languages}
              onSubmit={handleUserSubmit}
              onCancel={handleModalClose}
            />
          )}
        </>
      ),
    [user, languages, isModalVisible, handleUserSubmit, handleModalClose],
  )

  return {
    EditUserModal: component,
    isVisible: isModalVisible,
  }
}
