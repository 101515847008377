import { UserType } from '../shared/models/UserType'
import { BASE_API_URL } from './constants'
import { formatSystemDate } from '../shared/utils/formatTimestamp'

/**
 * @param {Object} args
 * @param {string} args.userId
 * @param {number} args.organizationId
 * @param {number} [args.patientId]
 * @param {number[]} args.serviceIds
 * @param {Object} args.patient
 * @param {string} args.description
 * @param {File[]} args?.file
 * @returns
 */
export default async ({ userId, organizationId, patientId, serviceIds, patient, description, file }) => {
  const patientToSend = {
    ...patient,
    userType: UserType.PATIENT,
    language: patient.language || 'English',
    description: description,
    dateOfBirth: formatSystemDate(patient.dateOfBirth)
  }

  const postData = (serviceId) => {
    const formData = new FormData();
  
    const json = JSON.stringify({
      organizationId: organizationId,
      patientId: patientId || null,
      serviceId: serviceId,
      patient: patientToSend,
      description: description,
    })
    formData.append('serviceRequest', new File([json], 'file.json', { type: 'application/json' }))

    if (file) {
      formData.append('file', file[0], file?.[0]?.name)
    }
    return formData
  }

  // TODO: revert to single request when backend will be able to receive many serviceIds
  // return fetch(`${BASE_API_URL}/v1/service-requests`, {
  //   method: 'POST',
  //   headers: {
  //     'userId': userId,
  //   },
  //   body: postData(),
  // }).then((response) => {
  //   if (!response.ok) {
  //     throw new Error(response.statusText)
  //   }
  //   return response.json()
  // })
  return Promise.all(
    serviceIds.map((serviceId) =>
      fetch(`${BASE_API_URL}/v1/service-requests`, {
        method: 'POST',
        headers: {
          'userId': userId,
        },
        body: postData(serviceId),
      }).then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response.json()
      }),
    ),
  )
}
