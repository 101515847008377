import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'

const getOrganization = async ({ queryKey }) => {
  const [_key, { id, userId }] = queryKey
  if (id == null) {
      return null
  }
  return await fetch(`${BASE_API_URL}/v1/organizations/${id}`, { headers: { 'userId': userId } }).then((response) =>
    response.json(),
  )
}

export default function useOrganization(id) {
  const { userId } = useContext(AuthContext)

  return useQuery([`organization-${id}`, { id, userId }], getOrganization, { refetchOnWindowFocus: false })
}
