import { User } from "../shared/models/User"
import { BASE_API_URL } from "./constants"

export const promoteUserToAdmin = async ({ user, userId }: { user: User, userId: string }): Promise<any> => {
    return fetch(`${BASE_API_URL}/v1/users/${user?.id}/promote-admin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userId': userId,
        }
    }).then(response => response.json())
}

export const revokeAdmin = async ({ user, userId }: { user: User, userId: string }): Promise<any> => {
    return fetch(`${BASE_API_URL}/v1/users/${user?.id}/revoke-admin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userId': userId,
        }
    }).then(response => response.json())
}

export const activateUser = async ({ user, userId }: { user: User, userId: string }): Promise<any> => {
    return fetch(`${BASE_API_URL}/v1/users/${user?.id}/activate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userId': userId,
        }
    }).then(response => response.json())
}

export const terminateUser = async ({ user, userId }: { user: User, userId: string }): Promise<any> => {
    return fetch(`${BASE_API_URL}/v1/users/${user?.id}/terminate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userId': userId,
        }
    }).then(response => response.json())
}