import React, {
  useState,
  useCallback,
  FunctionComponent,
  useMemo,
  useEffect,
} from 'react'
import { IonContent, IonBackdrop } from '@ionic/react'
import { RouteProps } from 'react-router-dom'
import { useIsFetching } from 'react-query'

import Page from '../page/Page'
import { useNewServiceRequestModal } from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'

import { getCurrentUser, getCognitoAttribute } from '../authentication/auth'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import TabContent from './components/TabContent/TabContent'
import SERVICE_REQUESTS_TABS from '../shared/utils/tabs'

export const OrgIncomingServiceRequests: FunctionComponent<RouteProps> = (
  props: RouteProps,
) => {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const isFetching = useIsFetching()

  /// Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  /// New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [setSuccessModalVisible])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
  } = useNewServiceRequestModal({
    onSuccess: handleNewServiceRequestSuccess,
  })

  const onSelectTab = (index: number) => setTabIndex(index)

  return (
    <IonContent>
      {isFetching ? <IonBackdrop /> : null}
      <Page name='Incoming Service Requests'>
        <div className={styles.pageWrapper}>
          <div className={styles.tabsContainer}>
            <Tabs selectedIndex={tabIndex} onSelect={onSelectTab}>
              <TabList className={styles.tabList}>
                {SERVICE_REQUESTS_TABS.map((tab, index) => (
                  <Tab className={styles.tabItem} key={index}>
                    {tab.label}
                  </Tab>
                ))}
              </TabList>
              {SERVICE_REQUESTS_TABS.map((item) => (
                <TabPanel key={item.type} className={styles.tabsContainer}>
                  <TabContent
                    type={item.type}
                    showNewServiceRequest={showNewServiceRequest}
                    loggedInUserOrgId={loggedInUserOrgId}
                  />
                </TabPanel>
              ))}
            </Tabs>

            {isNewServiceRequestPopupVisible ? <NewServiceRequest /> : null}
            {isSuccessModalVisible && (
              <SuccessModal
                onClose={handleSuccessModalClose}
                text='The request was created, someone from the CBO or Nonprofit should be in
               contact with the patient soon.'
              />
            )}
          </div>
        </div>
      </Page>
    </IonContent>
  )
}
