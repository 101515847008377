import React from 'react'
import { IonButton, IonSpinner } from '@ionic/react'
import classnames from 'classnames'

import { EmployeeServiceRequestsProps } from './interfaces'

import styles from './styles.module.scss'
import Table from '../../../table/Table'
import { ServiceRequestStatusText } from '../../../shared/models/ServiceRequestStatuses'
import { formatTimestampToShortDate } from '../../../shared/utils/formatTimestamp'

const serviceRequestsTableColumns = ({
  onServiceRequestView,
}: {
  onServiceRequestView: (id: number) => void
}) => [
  {
    Header: 'View Request',
    Cell: ({
      row: {
        // @ts-ignore
        original: { id },
      },
    }) => (
      <span
        className={styles.serviceRequestView}
        onClick={() => onServiceRequestView(id)}
      >
        {id}
      </span>
    ),
  },
  {
    Header: 'Service Request',
    accessor: 'organization.name',
  },
  {
    Header: 'Date Sent',
    accessor: 'createTimestamp',
    Cell: ({
      row: {
        original: { createTimestamp },
      },
    }: any) => formatTimestampToShortDate(createTimestamp),
  },
  {
    Header: 'Date Closed',
    Cell: ({ row: { original } }: any) => {
      return !!original.completeTimestamp
        ? formatTimestampToShortDate(original.completeTimestamp)
        : '--'
    },
  },
  {
    Header: 'Request Status',
    accessor: 'completed',
    // @ts-ignore
    Cell: ({ row }) => ServiceRequestStatusText[row.original.status],
  },
]

export function EmployeeServiceRequests({
  serviceRequests,
  isEmpServiceError,
  isEmpServiceLoading,
  onNewServiceRequestClick,
  onServiceRequestView,
}: EmployeeServiceRequestsProps) {
  if (isEmpServiceLoading) {
    return (
      <div className={styles.section}>
        <h5
          className={classnames(
            styles.sectionTitle,
            styles.serviceRequestsTitle,
          )}
        >
          Service Requests
          <IonSpinner />
        </h5>
      </div>
    )
  }

  if (isEmpServiceError) {
    return (
      <div className={styles.section}>
        <h5
          className={classnames(
            styles.sectionTitle,
            styles.serviceRequestsTitle,
          )}
        >
          Service Requests
        </h5>
        <div className={styles.errorMessage}>Something went wrong. Please try again later</div>
      </div>
    )
  }

  return (
    <div className={styles.section}>
      <h5
        className={classnames(styles.sectionTitle, styles.serviceRequestsTitle)}
      >
        Service Requests
        <IonButton
          className={styles.newServicesRequestButton}
          onClick={onNewServiceRequestClick}
        >
          New Services Request
        </IonButton>
      </h5>
      <div>
        <Table
          data={serviceRequests}
          columns={serviceRequestsTableColumns({ onServiceRequestView })}
        />
      </div>
    </div>
  )
}
