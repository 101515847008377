import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
} from '@ionic/react'
import Page from '../page/Page'
import styles from './styles.module.scss'
import Table from '../table/Table'
import usePatients from '../api/usePatients'
import { getCognitoAttribute, getCurrentUser } from '../authentication/auth'
import useFilterColumns from './useFilterColumns'
import StepContainer from './components/StepContainer'
import { Patient } from '../employee/interfaces'
import { useMutation } from 'react-query'
import { sendMessage } from '../api/useSendMessage'
import { AuthContext } from '../contexts/AuthContext'
import { SuccessModal } from '../shared/SuccessModal'
import { Error } from '../shared/utils/constants'
import { ErrorModal } from '../shared/ErrorModal'
import { useHistory } from 'react-router'

const PatientMessaging = () => {
  const history = useHistory()
  const { userId } = useContext(AuthContext)
  const [message, setMessage] = useState('')
  const[error, setError] = useState('')
  const [isSuccuessModal, setIsSuccessModal] = useState(false)

  const [isStepOneDone, setStepOneDone] = useState(false)
  const [isStepTwoDone, setStepTwoDone] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<Patient[]>([])
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const { data: patients = [], isFetching, isError } = usePatients(loggedInUserOrgId, {
    refetchOnWindowFocus: false,
    onError: (err) => {
      if (err.message == 403) {
        setError(Error.UNAUTHORIZE)
      }
    }
  })

  const { mutate: useSendMessage, isLoading } = useMutation(sendMessage, {
    onSuccess: () => {
      setIsSuccessModal(true)
    },
  })

  const columns = useFilterColumns()

  const onPatientSelect = (selected: any) => {
    setSelectedUsers(
      patients.filter((patient: Patient, i: number) => selected[i] === true),
    )
  }

  const onSendMessage = () => {
    const payload = {
      ids: selectedUsers.map((user) => user.id),
      message: message,
    }

    useSendMessage({ payload, userId })
  }

  const onCloseSuccessModal = () => {
    setIsSuccessModal(false)
    // Reset to initial state
    setMessage('')
    setStepOneDone(false)
    setStepTwoDone(false)
  }

  const handleDismiss = () => {
    setError('')
    history.goBack()
  }

  if (isError && error) {
    return (
      <ErrorModal
        onDismiss={handleDismiss}
        text={error}
      />
    )
  }


  return (
    <IonContent>
      <Page hideTopBar>
        <div className={styles.pageContainer}>
          <h1 className={styles.pageTitle}>Patient Messaging</h1>
          <p className={styles.pageDesc}>
            *All fields are required unless otherwise noted
          </p>
          <div className={styles.container}>
            <StepContainer
              step='1'
              title='Your Message'
              isStepDone={isStepOneDone}
              nextdisabled={!message}
              onClick={() => setStepOneDone(true)}
            >
              <div className={styles.messageView}>
                <IonItem>
                  <IonLabel position='floating'>SMS Message</IonLabel>
                  <IonInput
                    type='text'
                    value={message}
                    placeholder='Type out your event and/or announcement message here'
                    maxlength={300}
                    onIonChange={(e) => setMessage(e.detail.value as string)}
                  />
                </IonItem>
                <p className={styles.inputInfo}>300 Character Maximum</p>
              </div>
            </StepContainer>
            <StepContainer
              step='2'
              title='Use the table below to identiy who will receive the above message'
              isShow={isStepOneDone}
              isStepDone={isStepTwoDone}
              nextdisabled={!selectedUsers.length}
              onClick={() => setStepTwoDone(true)}
            >
              <div className={styles.tableView}>
                {!isFetching && (
                  <Table
                    data={patients}
                    columns={columns}
                    showFilters
                    getSelectedRows={onPatientSelect}
                  />
                )}
              </div>
            </StepContainer>
            <StepContainer
              step='3'
              title='If you are ready to send this message, then click the big blue button below.'
              isShow={isStepOneDone && isStepTwoDone}
              hasStepContent={false}
            >
              <div className={styles.buttonContainer}>
                <IonButton
                  onClick={onSendMessage}
                  className={styles.sendButton}
                  disabled={!selectedUsers.length || !message}
                  expand='full'
                >
                  {isLoading ? <IonSpinner color='dark' /> : `Send`}
                </IonButton>
              </div>
            </StepContainer>
          </div>
        </div>
      </Page>
      {isSuccuessModal && (
        <SuccessModal
          onClose={onCloseSuccessModal}
          text='Your message was sent successfully to the selected patient(s)'
        />
      )}
    </IonContent>
  )
}

export default PatientMessaging
