// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_page__1ErdA {\n  display: flex;\n  width: 100%;\n  height: fit-content;\n  padding: 34px;\n  background-color: #e5e5e5;\n  z-index: 1; }\n\n.styles_tablesWrapper__3L6rt {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1; }\n\n.styles_patientSurveysWrapper__1I9Rf {\n  margin-top: 65px; }\n", "",{"version":3,"sources":["webpack://src/patient/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EAEnB,aAAa;EAEb,yBAAyB;EAEzB,UAAU,EAAA;;AAGZ;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY,EAAA;;AAGd;EACE,gBAAgB,EAAA","sourcesContent":[".page {\n  display: flex;\n  width: 100%;\n  height: fit-content;\n\n  padding: 34px;\n\n  background-color: #e5e5e5;\n\n  z-index: 1;\n}\n\n.tablesWrapper {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n\n.patientSurveysWrapper {\n  margin-top: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "styles_page__1ErdA",
	"tablesWrapper": "styles_tablesWrapper__3L6rt",
	"patientSurveysWrapper": "styles_patientSurveysWrapper__1I9Rf"
};
export default ___CSS_LOADER_EXPORT___;
