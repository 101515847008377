import { ServiceRequestStatus } from '../shared/models/ServiceRequestStatuses'
import { BASE_API_URL } from './constants'

export const changeServiceRequestStatus = async ({
  serviceRequestId,
  newStatus,
  userId,
}: {
  serviceRequestId: string
  newStatus: ServiceRequestStatus
  userId: string
}) => {
  return fetch(
    `${BASE_API_URL}/v1/service-requests/${serviceRequestId}/transition/${newStatus}`,
    {
      method: 'POST',
      headers: {
        'userId': userId,
      }
    },
  ).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}
