import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  FunctionComponent,
} from 'react'
import { IonBackdrop, IonContent, IonPage } from '@ionic/react'
import { RouteProps } from 'react-router-dom'

import useUser from '../api/useUser'
import useUserSurveys from '../api/useUserSurveys'
import useUserServiceRequests from '../api/useUserServiceRequests'

import {
  getUserServiceRequestsQueryKey,
  updateUserServiceRequestInCache,
} from '../api/useUserServiceRequests'
import {
  getServiceRequestNotesQueryKey,
  addServiceRequestNoteInCache,
} from '../api/useServiceRequestNotes'

import Page from '../page/Page'
import { PatientSurveys } from './components/PatientSurveys'
import { PatientServiceRequests } from './components/PatientServiceRequests'
import { useNewServiceRequestModal } from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'
import { useServiceRequestReviewModal } from '../shared/ServiceRequestReviewModal'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useEditUserModal } from '../shared/EditUserModal'
import { UserInfo } from '../shared/UserInfo'
import { UserType } from '../shared/models/UserType'

const PatientProfile: FunctionComponent<RouteProps> = (props: RouteProps) => {
  // @ts-ignore
  const userId = props.match.params.id

  const { data: patient } = useUser(userId)
  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patient', patient)
  }, [patient])

  const { data: patientSurveys } = useUserSurveys(userId)
  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patientSurveys', patientSurveys)
  }, [patientSurveys])

  // Patient Service Requests
  const { data: patientServiceRequests } = useUserServiceRequests(userId)
  const getBindedPatientServiceRequestsQueryKey = useMemo(
    () => getUserServiceRequestsQueryKey.bind(null, userId),
    [userId],
  )

  // TODO: remove after data mutation is complete
  useEffect(() => {
    console.log('patientServiceRequests', patientServiceRequests)
  }, [patientServiceRequests])

  // Patient Surveys
  const [isAssignNewSurveyPopupVisible, setAssignNewSurveyPopupVisible] =
    useState(false)
  const handleAssignNewSurveyClick = useCallback(() => {
    setAssignNewSurveyPopupVisible(true)
  }, [])

  const handleSurveyView = useCallback((id) => {
    alert(`clicked at Survey ${id}`)
  }, [])

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  // New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
  } = useNewServiceRequestModal({
    patientId: +userId,
    onSuccess: handleNewServiceRequestSuccess,
    patient: patient,
  })

  // Service Request Preview
  const [serviceRequestChosen, setServiceRequestChosen] =
    useState<ServiceRequest | null>(null)
  const handleServiceRequestPreviewClose = useCallback(() => {
    setServiceRequestChosen(null)
  }, [setServiceRequestChosen])

  const handleServiceRequestViewClick = useCallback(
    (id) => {
      setServiceRequestChosen(
        (patientServiceRequests as ServiceRequest[]).find((i) => i.id === id) ||
          null,
      )
    },
    [setServiceRequestChosen, patientServiceRequests],
  )

  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        serviceRequestChosen?.id || NaN,
      ),
    [serviceRequestChosen?.id],
  )

  const {
    ServiceRequestReviewModal,
    isVisible: isServiceRequestReviewModalVisible,
  } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: handleServiceRequestPreviewClose,

    getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
    addServiceRequestNoteInCache: addServiceRequestNoteInCache,

    getQueryKeyForServiceRequests: getBindedPatientServiceRequestsQueryKey,
    updateServiceRequestInCache: updateUserServiceRequestInCache,
  })

  /// Patient edit
  const [isPatientEditing, setIsPatientEditing] = useState(false)
  const handlePatientEditClick = useCallback(() => {
    setIsPatientEditing(true)
  }, [setIsPatientEditing])
  // * Cancel
  const handleUserEditCancel = useCallback(() => {
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  // * Success
  const handleUserEditSuccess = useCallback(() => {
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  // * Error
  const handleUserEditError = useCallback(() => {
    alert('Something went wrong during updating user data')
    setIsPatientEditing(false)
  }, [setIsPatientEditing])
  const { EditUserModal, isVisible: isEditUserModalVisible } = useEditUserModal(
    {
      userId: userId,
      onSuccess: handleUserEditSuccess,
      onCancel: handleUserEditCancel,
      onError: handleUserEditError,
    },
  )

  const areSomePopupOpen =
    isNewServiceRequestPopupVisible ||
    isAssignNewSurveyPopupVisible ||
    isSuccessModalVisible ||
    isPatientEditing

  return (
    <IonContent>
      {areSomePopupOpen && <IonBackdrop className={styles.backdrop} />}
      <Page name='Patient Profile'>
        <div className={styles.page}>
          <UserInfo
            userType={UserType.PATIENT}
            user={patient}
            onEdit={handlePatientEditClick}
          />
          <div className={styles.tablesWrapper}>
            <PatientServiceRequests
              serviceRequests={patientServiceRequests}
              onNewServiceRequestClick={showNewServiceRequest}
              onServiceRequestView={handleServiceRequestViewClick}
            />
            {/* <div className={styles.patientSurveysWrapper}>
              <PatientSurveys
                surveys={patientSurveys}
                onAssignNewSurveyClick={() => {}}
                onSurveyView={handleSurveyView}
              />
            </div> */}
          </div>
        </div>
      </Page>
      <NewServiceRequest />
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='The request was created, someone from the CBO or Nonprofit should be in
        contact with the patient soon.'
        />
      )}
      {serviceRequestChosen && <ServiceRequestReviewModal />}
      {isPatientEditing && <EditUserModal />}
    </IonContent>
  )
}

export default PatientProfile
