import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSlides,
  IonSlide,
} from '@ionic/react'
import React, { useRef, useState } from 'react'
import HealthOpXLogo from '../public/assets/HealthOpXLogo.png'
import SignInDoctors from '../public/assets/signin-doctors.png'
import { arrowBack } from 'ionicons/icons'
import { RouteComponentProps } from 'react-router'
import ResetPassword from './components/ResetPassword'
import EnterEmail from './components/EnterEmail'
import './style.module.scss'
import ResetPasswordSuccess from './components/Success'

function ResetPasswordContainer({ history }: RouteComponentProps): JSX.Element {
  const slideRef = useRef<any>()
  const [email, setEmail] = useState('')

  const slideToNext = async (isSlide: boolean) => {
    if (isSlide) {
      await slideRef.current.slideNext()
    }
    await slideRef.current.lockSwipes()
  }

  return (
    <IonContent fullscreen>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonRow>
              <IonImg className='sign-in-img' src={SignInDoctors} />
            </IonRow>
          </IonCol>
          <IonCol size='6' style={{ paddingRight: '100px', marginTop: '8vh' }}>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonBackButton
                  text=''
                  icon={arrowBack}
                  defaultHref={'/login'}
                  className='custom-back-button'
                />
              </IonButtons>
            </IonToolbar>
            <IonRow>
              <IonCol>
                <div className='ion-text-start'>
                  <IonImg
                    style={{ marginTop: 10 }}
                    className='logo'
                    src={HealthOpXLogo}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-text-start'>
                <IonText id='login-subtitle'>
                  Technology connecting healthcare to community.
                </IonText>
              </IonCol>
            </IonRow>
            <IonSlides ref={slideRef}>
              <IonSlide>
                <EnterEmail next={slideToNext} setEmail={setEmail} />
              </IonSlide>
              <IonSlide>
                <ResetPassword next={slideToNext} email={email} />
              </IonSlide>
              <IonSlide>
                <ResetPasswordSuccess />
              </IonSlide>
            </IonSlides>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default ResetPasswordContainer
