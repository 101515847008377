import { BASE_API_URL } from "../api/constants";
import jwt_decode from "jwt-decode";
let decodedIdToken = {};

export const signUp = async (userInfo) => {
  userInfo.confirmPassword = userInfo.password
  return fetch(`${BASE_API_URL}/v1/auth/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return response.json()
  })
}

const clearSession = () => {
  localStorage.clear();
}

const setAuthContext = (jsonObj, setRefreshToken) => {
  localStorage.setItem('token', jsonObj.idToken);
  if (setRefreshToken) {
    localStorage.setItem('r=', jsonObj.refreshToken);
  }
  let decoded = jwt_decode(localStorage.getItem('token'));
  localStorage.setItem('o=', decoded['custom:organizationId']);
  localStorage.setItem('u=', decoded['custom:userId'] ? decoded['custom:userId'] : decoded['custom:id']);
}

export const authenticateUser = async (email, password) => {
  return fetch(`${BASE_API_URL}/v1/auth/login`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      password: password
    }),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message)
    } else {
      let user;
      await response.json().then((json) => {
        user = json.body;
        setAuthContext(json, true)
      });
      return user;
    }
  }).catch(error => {
    throw error
  })
}

export const reAuth = async () => {
  return fetch(`${BASE_API_URL}/v1/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: localStorage.getItem('r=')
    }),
  }).then(async (response) => {
    if (!response.ok) {
      clearSession();
      window.location.reload()
    } else {
      await response.json().then((json) => {
        setAuthContext(json, false)
        return json;
      });
    }
  })
}

export const signOut = () => {
  if (localStorage.getItem('token')) {
    clearSession()
    // TODO, create a signout api to sign out from the backend pool
    //userPool.getCurrentUser().signOut()
  }
  window.location.reload()
}

export const getCurrentUser = (callback) => {
  callback({}, { 'custom:userId': localStorage.getItem('u=') })
}

export const getCognitoAttribute = (attrs, attrName) => {
  if (attrName === 'custom:organizationId') {
    return localStorage.getItem('o=')
  } else if (attrName === 'custom:userId') {
    return localStorage.getItem('u=')
  } else {
    if (Object.keys(decodedIdToken).length === 0) {
      decodedIdToken = jwt_decode(localStorage.getItem('token'));
    }
    return decodedIdToken[attrName];
  }
}

