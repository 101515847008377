import React, { useCallback, useState, useMemo, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { NewServiceRequest } from '.'
import { NewServiceRequestFormValues } from './interfaces'
import createServiceRequest from '../../api/createServiceRequest'
import { User } from '../models/User'
import useLanguages from '../../api/useLanguages'
import { AuthContext } from '../../contexts/AuthContext'

export function useNewServiceRequestModal(props: {
  patientId?: number
  onSuccess: () => void
  onError?: () => void
  onSettled?: () => void
  patient?: User
}) {
  const { userId } = useContext(AuthContext)
  const queryClient = useQueryClient()

  const { data: languages } = useLanguages()

  const [isPopupVisible, setPopupVisible] = useState(false)

  const handleShow = useCallback(() => {
    setPopupVisible(true)
  }, [])
  const handleHide = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const { mutate: mutateCreateServiceRequest, isLoading } = useMutation(
    createServiceRequest,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          `user-${props.patientId}-service-requests`,
        )
        queryClient.invalidateQueries(`service-requests`)
      },
    },
  )

  const handleSubmit = useCallback(
    (formValues: NewServiceRequestFormValues) => {
      console.log('Submitting Service Request. Form values: ', formValues)
      const {
        organizationId,
        serviceIds,
        description,
        files,
        ...patientFormValues
      } = formValues
      mutateCreateServiceRequest(
        {
          userId: userId,
          organizationId: organizationId as number,
          serviceIds: serviceIds,
          patientId: props.patientId,
          patient: {
            organizationIds: [organizationId],
            ...patientFormValues,
          },
          description: description,
          ...(files.length && { file: files }),
        },
        {
          onSuccess: () => {
            props.onSuccess()
          },
          onError: () => {
            props.onError && props.onError()
          },
          onSettled: () => {
            setPopupVisible(false)
            props.onSettled && props.onSettled()
          },
        },
      )
    },
    [props.patientId],
  )

  const handleCancel = useCallback(() => {
    setPopupVisible(false)
  }, [])

  const component = useMemo(() => {
    return () => (
      <>
        {isPopupVisible && languages && (
          <NewServiceRequest
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            patient={props.patient}
            languages={languages}
            isLoading={isLoading}
          />
        )}
      </>
    )
  }, [isPopupVisible, handleSubmit, handleCancel, isLoading])

  return {
    isVisible: isPopupVisible,
    show: handleShow,
    hide: handleHide,
    NewServiceRequest: component,
  }
}
