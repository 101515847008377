import React from 'react'
import { caretDownSharp } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

const Types = {
  SELECT: 'select',
}

const FiltersHeader = ({ headerGroups }) => {
  return (
    <thead slot='fixed'>
      {headerGroups.map((item) => {
        return (
          <tr {...item.getHeaderGroupProps()}>
            {item.headers.map((column, index) => {
              if (column.id === Types.SELECT) {
                return <td key={index} />
              }
              return (
                <td
                  // Filter rows by given filter options
                  // {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                >
                  <div>
                    <p>{column.render('Header')}</p>
                    <IonIcon slot='end' icon={caretDownSharp} />
                  </div>
                </td>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default FiltersHeader
