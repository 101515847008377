import { IonIcon } from '@ionic/react'
import React, { useMemo } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { documentTextOutline } from 'ionicons/icons'
import styles from './styles.module.scss'

interface IProps {
  acceptedTypes: string
  maxFiles: number
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void
}

const baseStyle: React.CSSProperties = {
  flex: 1,
  height: 117,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#ccc',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(189, 235, 250, 0.25)',
  color: '##6D6D6D',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function FileDropzone({ acceptedTypes, maxFiles, onDrop }: IProps) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: acceptedTypes, onDrop, maxFiles })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  const renderFile = (file: File, index: number) => {
    return (
      <div key={index} className={styles.previewContainer}>
        {file.type.includes('image') ? (
          <img
            key={index}
            src={URL.createObjectURL(file)}
            alt={file.name}
            className={styles.image}
          />
        ) : (
          <IonIcon icon={documentTextOutline} className={styles.icon} />
        )}
        <span className={styles.fileName}>{file.name}</span>
      </div>
    )
  }

  return (
    <>
      {acceptedFiles.length ? (
        acceptedFiles.map(renderFile)
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div>
            <span>Drag and Drop to upload file or</span>
            <span className={styles.fileUpload}>Choose file</span>
          </div>
        </div>
      )}
    </>
  )
}

export default FileDropzone
