import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  validEmail,
  validPhoneNumber,
} from '../validation/dist/SignUpValidation'
import FileUploadIcon from '../../assets/icon/file.png'
import styles from './styles.module.scss'
import { OrganizationData } from '../util'

interface IProps {
  next: (fields: any) => void
  orgProfile: OrganizationData
  setOrgProfile: any
  error?: string
}

const orgTypes = [
  { label: 'Care Provider', value: 'CARE_PROVIDER' },
  { label: 'Service Provider', value: 'SERVICE_PROVIDER' },
]

const AddOranizationProfile = ({
  next,
  orgProfile,
  setOrgProfile,
  error,
}: IProps): JSX.Element => {
  const [file, setFile] = useState<string | ArrayBuffer | null>('')

  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: OrganizationData) => {
    setOrgProfile(data)
    const result = await trigger([
      'name',
      'organizationType',
      'email',
      'phoneNumber',
      'address',
      'city',
      'state',
      'zipCode',
      'website',
      'description',
    ])
    next(result)
  }

  const renderLabel = (inputError: any, label: string) => {
    if (inputError) {
      return (
        <IonLabel position='floating' color='danger'>
          {inputError.message}
        </IonLabel>
      )
    }
    return <IonLabel position='floating'>{label}</IonLabel>
  }

  const fileInput = register('logo')

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            Tell us about your organization
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            Provide your organization's information below
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <form
          className={styles.form}
          onSubmit={handleSubmit((data) =>
            onSubmit({ ...orgProfile, ...data }),
          )}
        >
          <IonCol className='ion-text-center'>
            <IonList>
              <IonItem>
                {renderLabel(errors.name, 'Company Name')}
                <IonInput
                  {...register('name', {
                    required: 'Company Name is required.',
                  })}
                  name='name'
                />
              </IonItem>
              <IonItem>
                {renderLabel(errors.organizationType, 'Type of Organization')}
                <IonSelect
                  {...register('organizationType', {
                    required: 'Organization type is required.',
                  })}
                  name='organizationType'
                >
                  {orgTypes.map((type, i) => {
                    return (
                      <IonSelectOption key={i} value={type.value}>
                        {type.label}
                      </IonSelectOption>
                    )
                  })}
                </IonSelect>
              </IonItem>
              <IonItem>
                {renderLabel(errors.phoneNumber, 'Phone Number')}
                <IonInput
                  {...register('phoneNumber', {
                    validate: (value) => {
                      if (value) {
                        return (
                          validPhoneNumber(value) ||
                          'Must be a valid Phone Number.'
                        )
                      }
                      return true
                    },
                  })}
                  name='phoneNumber'
                  placeholder='+12223334444'
                />
              </IonItem>
              <IonItem>
                {renderLabel(errors.email, 'Email')}
                <IonInput
                  {...register('email', {
                    validate: (value) => {
                      if (value) {
                        return validEmail(value) || 'Must be a valid Email.'
                      }
                      return true
                    },
                  })}
                  type='email'
                  name='email'
                />
              </IonItem>
              <IonItem>
                {renderLabel(errors.address, 'Address')}
                <IonInput {...register('address', {})} name='address' />
              </IonItem>
              <IonRow>
                <IonCol>
                  <IonItem>
                    {renderLabel(errors.city, 'City')}
                    <IonInput {...register('city', {})} name='city' />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    {renderLabel(errors.state, 'State')}
                    <IonInput {...register('state', {})} name='state' />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    {renderLabel(errors.zipCode, 'Zip Code')}
                    <IonInput {...register('zipCode', {})} name='zipCode' />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonItem>
                {renderLabel(errors.website, 'Website')}
                <IonInput {...register('website')} name='website' type='url' />
              </IonItem>
              <IonItem>
                {renderLabel(errors.description, 'Company Bio')}
                <IonInput {...register('description', {})} name='description' />
              </IonItem>
            </IonList>
            <IonRow>
              <div className={styles.fileUplaodContainer}>
                {file ? (
                  <img src={file as string} height={'100%'} />
                ) : (
                  <>
                    <span className={styles.circle}>
                      <IonImg
                        className={styles.icon}
                        src={FileUploadIcon}
                      ></IonImg>
                    </span>
                    <p className={styles.logoplaceholderLabel}>
                      Upload a company profile image (jpg/png)
                    </p>
                    <input
                      type='file'
                      {...fileInput}
                      accept='image/*'
                      onChange={(event) => {
                        fileInput.onChange(event)
                        if (event.target.files) {
                          setFile(URL.createObjectURL(event.target.files[0]))
                        }
                      }}
                      className={styles.fileUploadInput}
                    />
                  </>
                )}
              </div>
            </IonRow>
            {error && (
              <IonItem>
                <IonText className='ion-text-center' color='danger'>
                  {error}
                </IonText>
              </IonItem>
            )}
            <IonButton
              shape='round'
              fill='outline'
              expand='full'
              color='dark'
              className='next-button'
              type='submit'
            >
              NEXT
            </IonButton>
          </IonCol>
        </form>
      </IonRow>
    </IonGrid>
  )
}

export default AddOranizationProfile
