export const defaultNotificationOptions = {
  container: 'top-right' as
    | 'top-right'
    | 'top-full'
    | 'top-left'
    | 'top-center'
    | 'center'
    | 'bottom-full'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-center',
  animationIn: ['animate__animated', 'animate__fadeInRight'],
  animationOut: ['animate__animated', 'animate__fadeOutRight'],
  dismiss: { pauseOnHover: true, duration: 5000 },
}
