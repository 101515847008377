"use strict";
exports.__esModule = true;
exports.validPassword = exports.validEmail = exports.validPhoneNumber = exports.validZipcode = void 0;
exports.validZipcode = function (zipcode) {
    var validZipcode = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
    return validZipcode.test(zipcode);
};
exports.validPhoneNumber = function (phoneNumber) {
    var validPhoneNumber = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$');
    return validPhoneNumber.test(phoneNumber);
};
exports.validEmail = function (email) {
    var validEmail = new RegExp('[^s@]+@[^s@]+.[^s@]+$');
    return validEmail.test(email);
};
exports.validPassword = function (password) {
    var validPasswordSpecialChar = new RegExp('(?=.*[!@#$%^&*])');
    var hasSpecialCharacter = validPasswordSpecialChar.test(password);
    if (!hasSpecialCharacter)
        return {
            valid: false,
            error: 'Must contain at least one special character.'
        };
    var validPasswordCapitalLetter = new RegExp('(.*[A-Z].*)');
    var hasCapitalLetter = validPasswordCapitalLetter.test(password);
    if (!hasCapitalLetter)
        return { valid: false, error: 'Must contain at least one capital letter.' };
    var validPasswordNumber = new RegExp('(.*[0-9].*)');
    var hasNumber = validPasswordNumber.test(password);
    if (!hasNumber)
        return { valid: false, error: 'Must contain at least one number.' };
    return { valid: true, error: '' };
};
