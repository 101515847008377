import React, { useContext, useState } from 'react'

import Page from '../page/Page'

import styles from './styles.module.scss'
import { AuthContext } from '../contexts/AuthContext'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import TabContent from './components/TabContent/TabContent'
import SERVICE_REQUESTS_TABS from '../shared/utils/tabs'

function ServiceRequests() {
  const [tabIndex, setTabIndex] = useState(0)

  const { userAttributes } = useContext(AuthContext)

  const onSelect = (index: number) => setTabIndex(index)

  return (
    <Page name='Service Requests'>
      <div className={styles.pageWrapper}>
        <div className={styles.tabsContainer}>
          <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
            <TabList className={styles.tabList}>
              {SERVICE_REQUESTS_TABS.map((tab, index) => (
                <Tab className={styles.tabItem} key={index}>
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            {SERVICE_REQUESTS_TABS.map((item) => (
              <TabPanel key={item.type} className={styles.tabsContainer}>
                <TabContent type={item.type} orgId={userAttributes.OrgId} />
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </Page>
  )
}

export default ServiceRequests
