// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__1ZGhv {\n  height: 100vh; }\n  .styles_container__1ZGhv div {\n    height: 100vh;\n    width: 50vw;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: space-between; }\n  .styles_container__1ZGhv p {\n    color: #464646;\n    font-size: 20px; }\n\n.styles_errorTitle__Sl_6q {\n  color: #09395f;\n  font-size: 35px;\n  font-weight: bold; }\n\n.styles_errorDetails__2XO7l {\n  padding: 20px; }\n\n.styles_errorIcon__JcrNx {\n  height: 200px;\n  width: 200px; }\n\n.styles_nextButton__7nVvV {\n  margin-top: 50px;\n  color: #fff;\n  width: 250px;\n  --background: #09395f; }\n", "",{"version":3,"sources":["webpack://src/error-boundary/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAII,aAAa;IACb,WAAW;IACX,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,8BAA8B,EAAA;EATlC;IAaI,cAAc;IACd,eAAe,EAAA;;AAInB;EACE,cAAc;EACd,eAAe;EACf,iBAAiB,EAAA;;AAGnB;EACE,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,qBAAa,EAAA","sourcesContent":[".container {\n  height: 100vh;\n\n  div {\n    height: 100vh;\n    width: 50vw;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  p {\n    color: #464646;\n    font-size: 20px;\n  }\n}\n\n.errorTitle {\n  color: #09395f;\n  font-size: 35px;\n  font-weight: bold;\n}\n\n.errorDetails {\n  padding: 20px;\n}\n\n.errorIcon {\n  height: 200px;\n  width: 200px;\n}\n\n.nextButton {\n  margin-top: 50px;\n  color: #fff;\n  width: 250px;\n  --background: #09395f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__1ZGhv",
	"errorTitle": "styles_errorTitle__Sl_6q",
	"errorDetails": "styles_errorDetails__2XO7l",
	"errorIcon": "styles_errorIcon__JcrNx",
	"nextButton": "styles_nextButton__7nVvV"
};
export default ___CSS_LOADER_EXPORT___;
