import React, { useRef, useState } from 'react'
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
  IonAlert,
} from '@ionic/react'
import SignInDoctors from '../public/assets/signin-doctors.png'
import HealthOpXLogo from '../public/assets/HealthOpXLogo.png'

import { FormProvider, useForm } from 'react-hook-form'
import {
  AdminData,
  OrganizationData,
  OrganizationService,
  UserType,
} from './util'
import AddOranizationProfile from './components/AddOrganizationProfile'
import AddOrganizationService from './components/AddOrganizationServices'
import TermsOfServices from './components/TermsOfService'
import { useMutation } from 'react-query'
import { createOrganization } from '../api/createOrganization'
import useLanguages from '../api/useLanguages'
import { signUp } from '../authentication/auth'
import AdminSignUp from './components/AdminSignup'
import VerifyAccount from './VerifyAccount'

function AddOrganizationDesktop(): JSX.Element {
  const slideRef = useRef<any>(null)
  const formProps = useForm()
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')
  const [orgErrorMessage, setOrgErrorMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [error, setError] = useState('')
  const { data: languages } = useLanguages()

  const [adminData, setAdminData] = useState<AdminData>({
    organizationId: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    language: 'English',
    languageId: '',
    userType: UserType.ADMIN,
  })
  const [orgProfile, setOrgProfile] = useState<OrganizationData>({
    name: '',
    organizationType: '',
    phoneNumber: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    website: '',
    description: '',
    logo: [],
  })
  const [orgService, setOrgService] = useState<OrganizationService>({
    name: '',
    type: '',
    description: '',
  })

  // Sign up admin user after organization creation
  const { mutate: createAdminUser, isLoading } = useMutation(signUp, {
    onSuccess: async () => {
      setError('')
      // slide to success view
      await slideRef.current.slideNext()
    },
    onError: async (err: any) => {
      setError(err.message)
      await slideRef.current.slidePrev()
    },
  })


  // Create organization
  const {
    mutate: useCreateOrganization,
    isLoading: createOrgLoading,
  } = useMutation(createOrganization, {
    onSuccess: (data) => {
      const defaultLanguage = languages?.find((l) => l.name === 'English')
      adminData['languageId'] = defaultLanguage?.id
      adminData['organizationId'] = data?.id
      adminData['organizationIds'] = [data?.id]
      createAdminUser(adminData)
    },
    onError: async (err: any) => {
      // Error
      setOrgErrorMessage(err.message)
      setShowAlert(true)
    },
  })

  // Create organization payload, call mutation
  const createOrgProfile = () => {
    const org = {
      name: orgProfile.name,
      organizationType: orgProfile.organizationType,
      email: orgProfile.email,
      phoneNumber: orgProfile.phoneNumber,
      addressLine1: orgProfile.address,
      addressLine2: orgProfile.state,
      city: orgProfile.city,
      zipCode: orgProfile.zipCode,
      website: orgProfile.website,
      description: orgProfile.description,
      serviceDTOList: [
        {
          type: orgService.type,
          name: orgService.name,
          description: orgService.description,
        },
      ],
    }

    const data = new FormData()
    if (orgProfile.logo && orgProfile.logo.length) {
      data.append('file', orgProfile.logo[0], orgProfile.logo[0]?.name)
    }
    data.append('organization', JSON.stringify(org))

    // POST
    useCreateOrganization(data)
  }

  const handleAccept = async (isAccepted: boolean) => {
    if (!isAccepted) {
      await slideRef.current.slideTo(0)
      return
    }

    if (isAccepted) {
      createOrgProfile()
    }
  }

  const onAlertDismiss = async () => {
    setShowAlert(false)
    if (orgErrorMessage) {
      await slideRef.current.slideTo(0)
    } else {
      await slideRef.current.slideTo(1)
    }
  }

  const nextSlide = async (result: any) => {
    if (!result) return
    await slideRef.current.slideNext()
  }

  return (
    <IonContent fullscreen className='ion-padding-horizontal'>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonRow>
              <IonImg className='sign-in-img' src={SignInDoctors} />
            </IonRow>
          </IonCol>
          <IonCol size='6'>
            <IonRow>
              <IonCol>
                <div className='ion-text-start'>
                  <IonImg className='logo' src={HealthOpXLogo} />
                </div>
              </IonCol>
            </IonRow>
            <FormProvider {...formProps}>
              <IonSlides ref={slideRef}>
                <IonSlide>
                  <AddOranizationProfile
                    next={nextSlide}
                    orgProfile={orgProfile}
                    setOrgProfile={setOrgProfile}
                    error={orgErrorMessage}
                  />
                </IonSlide>
                <IonSlide>
                  <AddOrganizationService
                    next={nextSlide}
                    orgService={orgService}
                    setOrgService={setOrgService}
                    error={serviceErrorMessage}
                  />
                </IonSlide>
                <IonSlide>
                  <AdminSignUp
                    userData={adminData}
                    setUserData={setAdminData}
                    error={error}
                    next={nextSlide}
                  />
                </IonSlide>
                <IonSlide>
                  <TermsOfServices
                    setAccept={handleAccept}
                    isLoading={
                      createOrgLoading || isLoading
                    }
                  />
                </IonSlide>
                <IonSlide>
                  <VerifyAccount hideHeader={true} />
                </IonSlide>
              </IonSlides>
            </FormProvider>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={onAlertDismiss}
        header={'Alert'}
        message={orgErrorMessage || serviceErrorMessage}
        buttons={['Cancel', 'Ok']}
      />
    </IonContent>
  )
}

export default AddOrganizationDesktop
