import React from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonButton,
  IonText,
  IonList,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { useFormContext } from 'react-hook-form'
import { arrowBack } from 'ionicons/icons'
import useOrganizations from '../../../api/useOrganizations'

function EmployeeStepOne(props: { next: any }) {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const { data } = useOrganizations()

  const getOrganizations = () => {
    if (data) {
      return data.map((org: any, id: number) => {
        return (
          <IonSelectOption value={org.id} key={id.toString() + org.name}>
            {org.name}
          </IonSelectOption>
        )
      })
    }
  }
  return (
    <IonGrid>
      <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              text=''
              icon={arrowBack}
              defaultHref={'/login'}
              className='custom-back-button'
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonRow className='intro-container'>
        <IonCol>
          <IonText className='header-text'>{'Join your organization'}</IonText>
        </IonCol>
      </IonRow>
      <IonRow className='description-container'>
        <IonCol>
          <IonText className='description-text'>
            {'Find your organization below to get started'}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center'>
          <IonList>
            <IonItem>
              {errors.organizationId && (
                <IonLabel position='floating' color='danger'>
                  Please fill out required fields
                </IonLabel>
              )}
              <IonSelect
                {...register('organizationId', { required: true })}
                placeholder='Select an Organization'
                name='organizationId'
              >
                {getOrganizations()}
              </IonSelect>
            </IonItem>
          </IonList>
          <IonRow className='ion-text-end ion-padding-end'>
            <IonCol className='ion-text-end ion-margin-horizontal'>
              <IonText className='description-text'>Don't see yours?</IonText>
              <IonButton
                shape={'round'}
                fill={'outline'}
                expand={'full'}
                color={'dark'}
                className={'next-button'}
                routerLink={'/add-organization'}
              >
                + Add Your Organization
              </IonButton>
            </IonCol>
          </IonRow>
          <IonButton
            shape='round'
            fill='outline'
            expand='full'
            color='dark'
            className='next-button'
            onClick={() => props.next(['organizationId'])}
          >
            NEXT
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default EmployeeStepOne
