import React from 'react'
import { IonContent } from '@ionic/react'
import Page from '../../page/Page'
import styles from './styles.module.scss'

interface IProps {
  page: string
  message: string
}

export const ErrorMessage = ({
  page,
  message = 'Something went wrong. Please try again.',
}: IProps) => {
  return (
    <IonContent>
      <Page name={page}>
        <div className={styles.error}>{message}</div>
      </Page>
    </IonContent>
  )
}
