import { useMemo } from 'react'
import Select from 'react-select';

const State = {
  PENDING: '#ffebd0',
  ACTIVE: '#e0f9f5',
  TERMINATED: '#f6f6f6'
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: '#eeedee'
    },
    color: state.isDisabled ? '#9f9f9f' : '#000',
    backgroundColor: '#fff',
    fontFamily: 'Arial, Helvetica, sans-serif'
  }),
  control: (provided, state) => {
    let color = State.PENDING;
    if (state.getValue().length && state.getValue()[0]) {
      const value = state.getValue()[0];
      switch (value.value) {
        case 'active':
          color = State.ACTIVE;
          break;
        case 'pending':
          color = State.PENDING
          break;
        case 'terminated':
          color = State.TERMINATED;
          break;
        default:
          break;
      }
    }
    return {
      ...provided,
      backgroundColor: color,
      border: 0,
      fontFamily: 'Arial, Helvetica, sans-serif'
    }
  }
}

const statuses = [
  { value: 'active', label: 'Active ' },
  { value: 'terminated', label: 'Terminated' },
]

export default function useColumns(userId, activateUser, terminateUser, isAdmin) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'User Type',
        accessor: 'userType',
      },
      {
        Header: 'Employee Status',
        accessor: 'status',
        Cell: ({ cell: { row: { original: user } } }) => {
          let value

          switch (user.status) {
            case 'ACTIVE':
              value = { value: 'active', label: 'Active' }
              break
            case 'INACTIVE':
              value = { value: 'terminated', label: 'Terminated' }
              break
            case 'PENDING':
              value = { value: 'pending', label: 'Pending' }
              break
            default:
              break
          }

          return (
            <span onClick={e => e.stopPropagation()}>
              <Select
                defaultValue={value}
                onChange={value => {
                  if (value.value === 'terminated') {
                    terminateUser({ user, userId })
                  } else if (value.value === 'active') {
                    activateUser({ user, userId })
                  }
                }}
                options={statuses}
                styles={customStyles}
                hideSelectedOptions
                isDisabled={!isAdmin}
              />
            </span>
          )
        }
      }
    ],
    [isAdmin],
  )

  return columns
}
