import { useContext } from 'react'
import { useQuery } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'

const getUserSurveys = async ({ queryKey }) => {
  const [_key, { userId, currentUserId }] = queryKey
  return await fetch(`${BASE_API_URL}/v1/users/${userId}/surveys`, {
    method: 'GET',
    headers: { 
      'userId': currentUserId ,
    },
  }).then((response) => response.json())
}

export default function useUserSurveys(userId) {
  const { userId: currentUserId } = useContext(AuthContext)
  return useQuery(
    [`user-${userId}-surveys`, { userId: userId, currentUserId }],
    getUserSurveys,
  )
}
