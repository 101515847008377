import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import {
  IonButton,
  IonCheckbox,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { mailOutline } from 'ionicons/icons'
import { Controller, FormState, useForm, Validate } from 'react-hook-form'

import styles from './styles.module.scss'
import useOrganizations from '../../api/useOrganizations'
import useOrganizationServices from '../../api/useOrganizationServices'
import { getServiceTypeName } from './utils/serviceTypes'

import {
  NewServiceRequestFormValues,
  NewServiceRequestProps,
} from './interfaces'
import { OrganizationServices } from '../models/OrganizationServices'
import classNames from 'classnames'
import { getCurrentUser, getCognitoAttribute } from '../../authentication/auth'
import FileDropzone from '../FileDropzone/FileDropzone'

export function NewServiceRequest({
  onSubmit,
  onCancel,
  patient,
  languages,
  isLoading
}: NewServiceRequestProps) {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [isFileUplaodShow, setIsFileUplaodShow] = useState(false)
  const [file, setFile] = useState<File[]>([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const {
    register,
    setValue,
    handleSubmit,
    formState,
    control,
    watch,
  } = useForm<NewServiceRequestFormValues>({
    defaultValues: useMemo(
      () => ({
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        email: patient?.email,
        dateOfBirth: patient?.dateOfBirth,
        phoneNumber: patient?.phoneNumber,
        zipCode: patient?.zipCode,
        organizationId: loggedInUserOrgId,
        language: 'English',
        serviceIds: [],
      }),
      [patient, loggedInUserOrgId],
    ),
  })

  useEffect(() => {
    register('organizationId', { required: true, value: loggedInUserOrgId })
    register('language', { required: true, value: 'English' })
  }, [loggedInUserOrgId])

  const { data: organizations } = useOrganizations()

  const [chosenOrganizationId, setChosenOrganizationId] = useState()
  const { data: organizationServices } = 
    useOrganizationServices(chosenOrganizationId)
  const [availableServiceTypes, setAvailableServiceTypes] = useState<string[]>()

  const dateValidator = useCallback<Validate<string>>((value) => {
    if (!value || value === 'Invalid Date') {
      return 'This is a required field'
    }

    return true
  }, [])

  useEffect(() => {
    if (!organizationServices) {
      setAvailableServiceTypes([])
      return
    }

    const availableServiceTypes = [
      ...new Set(
        (organizationServices as Array<OrganizationServices>).map(
          (organizationService) => organizationService.type,
        ),
      ),
    ]

    setAvailableServiceTypes(availableServiceTypes)
  }, [organizationServices])

  const languageChoosen = watch('language')
  const handleLanguageChoose = useCallback(
    (e: CustomEvent) => {
      // @ts-ignore
      const language = e.target.value
      setValue('language', language)
    },
    [setValue],
  )

  const handleOrganizationChoose = useCallback(
    (e: CustomEvent) => {
      setAvailableServiceTypes(undefined)
      // @ts-ignore
      const organizationId = e.target.value
      setChosenOrganizationId(organizationId)
      setValue('serviceIds', [])
    },
    [setChosenOrganizationId, setValue],
  )

  const toggleFileUploadShow = () => {
    setIsFileUplaodShow(!isFileUplaodShow)
  }

  const onFileDrop = (files: File[]) => {
    setFile(files)
  }

  if (!organizations) {
    return null
  }

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContent}
      isOpen={true}
      parentSelector={() => document.querySelector('ion-app') || document.body}
      onRequestClose={onCancel}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>New Service Request</h2>
        <div className={styles.info}>
          Please fill out the form below to submit the request information.
          Someone from the CBO or Nonprofit should be in contact with the
          patient soon. &nbsp;
          <span className={styles.infoHours}>(24 to 48hrs)</span>
        </div>
        <form onSubmit={handleSubmit((data) => onSubmit({ ...data, files: file}))} noValidate>
          <div className={styles.patient}>
            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.firstName },
                    styles.firstName,
                  )}
                >
                  <IonLabel position='floating'>First Name</IonLabel>
                  <IonInput
                    type='text'
                    autocomplete='off'
                    {...register('firstName', { required: true })}
                  />
                  <IonIcon name='user' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'firstName')}
              </div>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.lastName,
                  })}
                >
                  <IonLabel position='floating'>Last Name</IonLabel>
                  <IonInput
                    type='text'
                    autocomplete='off'
                    {...register('lastName', { required: true })}
                  />
                  <IonIcon name='user' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'lastName')}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.email },
                    styles.email,
                  )}
                >
                  <IonLabel position='floating'>Email</IonLabel>
                  <IonInput
                    type='text'
                    autocomplete='off'
                    {...register('email', { required: false })}
                  />
                  <IonIcon
                    className={styles.standartIcon}
                    icon={mailOutline}
                    slot='end'
                  />
                </IonItem>
                {renderErrorMessage(formState, 'email')}
              </div>
              <div className={classNames(styles.field, styles.language)}>
                <IonItem
                  className={classNames({
                    [styles.itemError]: formState.errors.language,
                  })}
                >
                  <IonLabel position='floating'>Language</IonLabel>
                  <IonSelect
                    value={languageChoosen}
                    defaultValue={languageChoosen}
                    onIonChange={handleLanguageChoose}
                  >
                    {languages.map((language) => (
                      <IonSelectOption
                        key={language.name}
                        value={language.name}
                      >
                        {language.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                {renderErrorMessage(formState, 'language')}
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.dateOfBirth,
                  })}
                >
                  <IonLabel position='stacked'>Patient D.O.B.</IonLabel>
                  <IonInput
                    type='date'
                    {...register('dateOfBirth', {
                      required: false,
                      validate: dateValidator,
                    })}
                  />
                  <IonIcon name='cake' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'dateOfBirth')}
              </div>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames(
                    { [styles.itemError]: formState.errors.phoneNumber },
                    styles.phone,
                  )}
                >
                  <IonLabel position='floating'>Phone Number</IonLabel>
                  <IonInput
                    type='tel'
                    autocomplete='off'
                    {...register('phoneNumber')}
                  />
                </IonItem>
                {renderErrorMessage(formState, 'phoneNumber')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <IonItem
                  disabled={!!patient}
                  className={classNames({
                    [styles.itemError]: formState.errors.zipCode,
                  })}
                >
                  <IonLabel position='floating'>Patient Zip Code</IonLabel>
                  <IonInput
                    type='number'
                    autocomplete='off'
                    {...register('zipCode')}
                  />
                  <IonIcon name='pointOnMap' slot='end' />
                </IonItem>
                {renderErrorMessage(formState, 'zipCode')}
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.field, styles.organization)}>
                <IonItem
                  className={classNames({
                    [styles.itemError]: formState.errors.organizationId,
                  })}
                >
                  <IonLabel position='floating'>
                    Choose Organization to manage the Service Request
                  </IonLabel>
                  <IonSelect onIonChange={handleOrganizationChoose}>
                    {/* @ts-ignore */}
                    {organizations.map((organization) => (
                      <IonSelectOption
                        key={organization.id}
                        value={organization.id}
                      >
                        {organization.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                {renderErrorMessage(formState, 'organizationId')}
              </div>
            </div>
            {!availableServiceTypes?.length ? null : (
              <div className={styles.requiredServices}>
                <div className={styles.requiredServicesTitle}>
                  Select Required Services:
                </div>
                <p className={styles.requiredServicesInfo}>
                  Select All of the services needed from the list provided
                  below.
                </p>
                <div className={styles.requiredServicesBody}>
                  <div className={styles.requiredServicesColumn}>
                    {availableServiceTypes.map((serviceType) => (
                      <div key={serviceType} className={styles.requiredService}>
                        <div className={styles.requiredServiceType}>
                          {getServiceTypeName(serviceType)}
                        </div>
                        {(organizationServices as OrganizationServices[])
                          .filter((service) => service.type === serviceType)
                          .map((service) => (
                            <div
                              key={service.type + service.id}
                              className={styles.requiredServiceOption}
                            >
                              <IonItem lines='none'>
                                <Controller
                                  name='serviceIds'
                                  control={control}
                                  shouldUnregister={true}
                                  rules={{ required: true, minLength: 1 }}
                                  render={({ field: { onChange, value } }) => (
                                    <IonCheckbox
                                      title={String(service.id)}
                                      value={String(service.id)}
                                      checked={(value || []).includes(
                                        service.id,
                                      )}
                                      onIonChange={(e) => {
                                        const isChecked = e?.detail.checked
                                        setValue(
                                          'serviceIds',
                                          isChecked
                                            ? [...value, service.id]
                                            : value.filter(
                                                (i) => i !== service.id,
                                              ),
                                        )
                                      }}
                                    />
                                  )}
                                />
                                <IonLabel>{service.name}</IonLabel>
                              </IonItem>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                </div>
                {formState?.errors.serviceIds && (
                  <span className='ion-padding-start'>
                    {renderErrorMessage(formState, 'serviceIds')}
                  </span>
                )}
              </div>
            )}

            <div className={styles.additionalNotesWrapper}>
              <IonItem className={styles.additionalNotes}>
                <IonLabel position='floating'>
                  Additional Notes about the Patient (non medical info.)
                </IonLabel>
                <IonTextarea
                  rows={4}
                  placeholder='Enter any important details here...'
                  {...register('description')}
                />
                <IonIcon name='pencil' slot='end' />
              </IonItem>
            </div>

            <div className={styles.fileUplaod}>
              <IonItem>
                <IonCheckbox onIonChange={toggleFileUploadShow} />
                <IonLabel>Upload Patient Files:</IonLabel>
              </IonItem>
              {isFileUplaodShow && (
                <FileDropzone
                  acceptedTypes='image/png, image/jpeg, image/jpg, .pdf'
                  onDrop={onFileDrop}
                  maxFiles={1}
                />
              )}
            </div>

            <div className={styles.buttons}>
              <IonButton
                shape='round'
                className={styles.cancelButton}
                onClick={onCancel}
              >
                Cancel
              </IonButton>
              <IonButton
                type='submit'
                shape='round'
                className={styles.submitButton}
                disabled={isLoading}
              >
                {isLoading ? <IonSpinner color='dark' /> : 'Submit'}
              </IonButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

function renderErrorMessage(
  formState: FormState<NewServiceRequestFormValues>,
  name: string,
) {
  if (name in formState?.errors) {
    return (
      <IonText color='danger'>
        <small>This is a required field</small>
      </IonText>
    )
  }

  return null
}
