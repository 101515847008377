// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_previewContainer__2gZJt {\n  display: flex;\n  flex-direction: row;\n  align-items: center; }\n\n.styles_image__3DiAw {\n  height: 80px;\n  border: 1px solid #ddd;\n  border-radius: 5px; }\n\n.styles_icon__1MShe {\n  height: 80px;\n  width: 80px; }\n\n.styles_fileName__1fUrt {\n  margin-left: 10px; }\n\n.styles_fileUpload__1LyLZ {\n  margin-left: 10px;\n  background-color: #fff;\n  padding: 10px;\n  border-radius: 50px; }\n", "",{"version":3,"sources":["webpack://src/shared/FileDropzone/styles.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAa;EACb,mBAAmB;EACnB,mBAAmB,EAAA;;AAGvB;EACI,YAAY;EACZ,sBAAsB;EACtB,kBAAkB,EAAA;;AAGtB;EACI,YAAY;EACZ,WAAW,EAAA;;AAGf;EACI,iBAAiB,EAAA;;AAGrB;EACI,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,mBAAmB,EAAA","sourcesContent":["\n\n.previewContainer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.image {\n    height: 80px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n}\n\n.icon {\n    height: 80px;\n    width: 80px;\n}\n\n.fileName {\n    margin-left: 10px;\n}\n\n.fileUpload {\n    margin-left: 10px;\n    background-color: #fff;\n    padding: 10px;\n    border-radius: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewContainer": "styles_previewContainer__2gZJt",
	"image": "styles_image__3DiAw",
	"icon": "styles_icon__1MShe",
	"fileName": "styles_fileName__1fUrt",
	"fileUpload": "styles_fileUpload__1LyLZ"
};
export default ___CSS_LOADER_EXPORT___;
