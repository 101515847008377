import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './PatientTable.module.scss'
import { IonContent, IonBackdrop, IonSpinner, IonButton } from '@ionic/react'
import Table from '../table/Table'
import useColumns from './useColumns'
import Page from '../page/Page'
import usePatients from '../api/usePatients'

import { getCurrentUser, getCognitoAttribute } from '../authentication/auth'
import { usePatientListUploadModal } from '../patient-list-upload-modal'
import { SuccessModal } from '../shared/SuccessModal'
import { ErrorMessage } from '../shared/ErrorMessage'
import { ErrorModal } from '../shared/ErrorModal'
import { Error } from '../shared/utils/constants'

export default function PatientTable() {
  const history = useHistory()
  const [filterInput, setFilterInput] = useState('')
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    getCurrentUser((attributes) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserOrgId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:organizationId')
  }, [currentUserAttributes])

  const { data: patients, isFetching, isError } = usePatients(
    loggedInUserOrgId,
    {
      onError: (err) => {
        if (err.message === '403') {
          setError(Error.UNAUTHORIZE)
        }
      },
    },
  )
  const columns = useColumns()

  const handleFilterChange = useCallback((e) => {
    const value = e.target.value || undefined
    setFilterInput(value)
  }, [])

  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handlePatientListUploadSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
    history.push('/service-requests')
  }, [])

  const {
    PatientListUploadModal,
    isVisible: isPatientListUploadModalVisible,
    show,
  } = usePatientListUploadModal(handlePatientListUploadSuccess)
  const handleUploadPatientsListButtonClick = useCallback(() => {
    show()
  }, [])

  const handleDismiss = useCallback(() => {
    setError('')
    history.goBack()
  })

  if (isError && error) {
    return <ErrorModal text={error} onDismiss={handleDismiss} />
  }

  if (isError) {
    return <ErrorMessage page='Patients' />
  }

  if (!patients) {
    return (
      <IonContent>
        <Page name='Patients'>
          <IonSpinner className={styles.loader} />
        </Page>
      </IonContent>
    )
  }

  return (
    <IonContent>
      {isFetching ? <IonBackdrop /> : null}
      <Page name='Patients'>
        <div className={styles.pageWrapper}>
          <div className={styles.tableWrapper}>
            <div className={styles.tableTopRow}>
              <input
                className={styles.searchBar}
                value={filterInput}
                onChange={handleFilterChange}
                placeholder='Search Patients...'
              />

              <IonButton
                className={styles.uploadList}
                onClick={handleUploadPatientsListButtonClick}
              >
                {'Upload your list'}
                <svg className={styles.uploadIcon} width='16' height='20'>
                  <image
                    xlinkHref='/assets/file-upload.svg'
                    src='/assets/file-upload.svg'
                    width='16'
                    height='20'
                  />
                </svg>
              </IonButton>
            </div>
            {!isFetching && (
              <Table
                data={patients}
                columns={columns}
                onClick={({ row }) =>
                  history.push(`/patients/${row?.original.id}`)
                }
                globalFilterInput={filterInput}
              />
            )}
          </div>
        </div>
      </Page>
      {isPatientListUploadModalVisible && <PatientListUploadModal />}
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='Your documents were uploaded successfully, your patient lists are now updated.'
        />
      )}
    </IonContent>
  )
}
