export enum UserType {
  PATIENT = 'PATIENT',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}

export interface PatientData {
  organizationId: number | null
  userType: UserType.PATIENT
  language: 'English' | string
  firstName: string
  lastName: string
  zipcode: string
  phoneNumber: string
  email: string
  password: string
}

export interface EmployeeData {
  userType: UserType.EMPLOYEE | UserType
  language: 'English' | string
  organizationId: number | null
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
}

export interface AdminData {
  userType: UserType.ADMIN
  language: string
  languageId: string | number | undefined
  organizationId: string | null
  organizationIds?: string[]
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  password: string
}

export interface OrganizationData {
  name: string
  organizationType: string
  phoneNumber: string
  email: string
  address: string
  city: string
  state: string
  zipCode: string
  website: string
  description: string
  logo: File[]
}

export interface OrganizationService {
  organizationId?: string
  name: string
  type: string
  description: string
}