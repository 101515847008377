import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import { OrganizationService } from '../util'

interface IProps {
  next: any
  orgService: OrganizationService
  setOrgService: (data: OrganizationService) => void
  error?: string
}

const serviceTypes = [
  { label: 'Food', value: 'FOOD' },
  { label: 'Translation', value: 'TRANSLATION' },
  { label: 'Transformation', value: 'TRANSPORTATION' },
  { label: 'Interpretation', value: 'INTERPRETATION' },
  { label: 'Scheduling', value: 'SCHEDULING' },
  { label: 'Child Care', value: 'CHILD_CARE' },
  { label: 'Ground Engagement', value: 'GROUND_ENGAGEMENT' },
  { label: 'Civic Engagement', value: 'CIVIC_ENGAGEMENT' },
  { label: 'Other', value: 'OTHER' },
]

const AddOrganizationService = ({
  next,
  orgService,
  setOrgService,
  error,
}: IProps): JSX.Element => {
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: OrganizationService) => {
    setOrgService(data)
    const result = await trigger(['name', 'serviceType', 'description'])
    next(result)
  }

  const renderLabel = (inputError: any, label: string) => {
    if (inputError) {
      return (
        <IonLabel position='floating' color='danger'>
          {inputError.message}
        </IonLabel>
      )
    }
    return <IonLabel position='floating'>{label}</IonLabel>
  }

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonText className={styles.headerText}>
            What service(s) do your organization provide the public?
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className={styles.descText}>
            Select All of the services needed from the list provided below, or
            write in any services
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-center'>
          <form
            autoComplete='off'
            onSubmit={handleSubmit((data) =>
              onSubmit({ ...orgService, ...data }),
            )}
          >
            <IonList>
              <IonRow>
                <IonCol>
                  <IonItem>
                    {renderLabel(errors.name, 'Service name')}
                    <IonInput
                      {...register('name', {
                        required: 'Service name is required.',
                      })}
                      name='name'
                    />
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    {renderLabel(errors.type, 'Service type')}
                    <IonSelect
                      {...register('type', {
                        required: 'Service type is required.',
                      })}
                      name='type'
                    >
                      {serviceTypes.map((type, i) => {
                        return (
                          <IonSelectOption key={i} value={type.value}>
                            {type.label}
                          </IonSelectOption>
                        )
                      })}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonItem>
                {renderLabel(errors.description, 'Service description')}
                <IonInput {...register('description', {})} name='description' />
              </IonItem>
            </IonList>

            {error && (
              <IonItem>
                <IonText color='danger'>{error}</IonText>
              </IonItem>
            )}

            <IonButton
              shape='round'
              fill='outline'
              expand='full'
              color='dark'
              className='next-button ion-margin-top'
              type='submit'
            >
              NEXT
            </IonButton>
          </form>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default AddOrganizationService
