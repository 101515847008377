import { useQuery } from 'react-query'

import { BASE_API_URL } from './constants'
import { Employee } from '../shared/models/Employee'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

export const getEmployees = async ({ queryKey }: any) => {
  const [_key, { organizationId, userId }] = queryKey

  return await fetch(
    `${BASE_API_URL}/v1/users?entityId=${organizationId}&entityType=ORGANIZATION&role=EMPLOYEE&status=ACTIVE`, {
    headers: {
      'userId': userId,
    },
  }).then((response) => response.json())
}

export function useEmployees(organizationId: number, { enabled = true, retry = 1 }) {
  const { userId } = useContext(AuthContext)

  return useQuery<Employee[]>([`employees`, { organizationId, userId }], getEmployees, {
    enabled,
    refetchOnWindowFocus: false,
    retry,
  })
}
