import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'

export function VerticalStep(props: {
  showLine?: boolean
  content: string | React.ReactNode
}) {
  return (
    <div
      className={classNames(styles.verticalStep, {
        [styles.verticalStepWithLine]: props.showLine,
      })}
    >
      <div className={styles.verticalStepContent}>{props.content}</div>
    </div>
  )
}
