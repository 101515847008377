import React from 'react'
import styles from '../Table.module.scss'

const Checkbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type='checkbox'
          className={styles.checkbox}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  },
)

export default Checkbox
