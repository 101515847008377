import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { EmployeeData, PatientData, UserType } from './util'
import {
  validZipcode,
  validPhoneNumber,
  validEmail,
  validPassword,
} from './validation/SignUpValidation'

export function useValidateSignUp(
  user: EmployeeData | PatientData,
): { status: boolean; error: Error[] } {
  const error: Error[] = []

  if (!user.firstName || user.firstName === '') {
    error.push(new Error('first name is required'))
  }
  if (!user.lastName || user.lastName === '') {
    error.push(new Error('last name is required'))
  }
  if (!user.organizationId) {
    error.push(new Error('organizationId is required'))
  }
  if (!user.phoneNumber || !validPhoneNumber(user.phoneNumber)) {
    error.push(new Error('phone number is required'))
  }
  if (!user.email || !validEmail(user.email)) {
    error.push(new Error('email is required'))
  }
  if (!user.password || !validPassword(user.password).valid) {
    error.push(new Error('password is required'))
  }
  switch (user.userType) {
    case UserType.PATIENT:
      const usr = user as PatientData
      if (!usr.zipcode || !validZipcode(usr.zipcode)) {
        error.push(new Error('zipcode is required'))
      }
  }
  if (error.length) {
    return { status: false, error }
  }
  return { status: true, error: [] }
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function useOnboardingForm(setData: any) {
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  }

  const slides = useRef<any>(null)

  const formMethods = useForm()
  const { trigger } = formMethods

  const switchSlide = async (prev: boolean, next: boolean) => {
    await slides.current.lockSwipes(false)
    if (next) await slides.current.slideNext()
    else if (prev) await slides.current.slidePrev()
    await slides.current.lockSwipes(true)
  }

  const next = async (fields: any) => {
    const result = await trigger(fields)
    if (!result) return
    switchSlide(false, true)
  }

  /* TODO: replace Ionic Icon button in forms with regular Ion Button 
       to allow for switching to previous slide
       
    const prev = () => {
        switchSlide(true, false)
    }

    */

  const onSubmit = (userData: PatientData | EmployeeData) => {
    const organizationIds = [userData.organizationId]
    setData({ ...userData, organizationIds: organizationIds })
  }

  return { slideOpts, slides, formMethods, next, onSubmit, trigger, switchSlide }
}
