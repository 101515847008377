import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useIsFetching, useMutation, useQueryClient } from 'react-query'

import { addNote } from '../../api/addNote'
import { assignServiceRequest } from '../../api/assignServiceRequest'
import { changeServiceRequestStatus } from '../../api/changeServiceRequestStatus'
import { useEmployees } from '../../api/useEmployeesNew'
import { useServiceRequestNotes } from '../../api/useServiceRequestNotes'

import { ServiceRequestReviewModal } from '.'
import { UseServiceRequestReviewModelProps } from './interfaces'
import { ServiceRequestStatus } from '../models/ServiceRequestStatuses'
import { ServiceRequest } from '../models/ServiceRequest'
import { ServiceRequestNote } from '../models/ServiceRequestNote'

import { getCurrentUser } from '../../authentication/auth'
import { AuthContext } from '../../contexts/AuthContext'

export function useServiceRequestReviewModal({
  serviceRequest,
  onClose,
  getQueryKeyForServiceRequests,
  updateServiceRequestInCache,
  getQueryKeyForNotes,
  addServiceRequestNoteInCache,
}: UseServiceRequestReviewModelProps) {
  const { userId } = useContext(AuthContext)
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const queryClient = useQueryClient()
  const isFetching = useIsFetching()

  // Modal model
  const [serviceRequestShown, setServiceRequestShown] =
    useState<ServiceRequest | null>(serviceRequest)
  useLayoutEffect(() => {
    setServiceRequestShown(serviceRequest)
  }, [serviceRequest])
  const isRequestChosen = !!serviceRequestShown

  // Modal visibility
  const [isModalVisible, setModalVisible] = useState(false)
  const handleHide = useCallback(() => {
    setModalVisible(false)
  }, [])

  const { data: allEmployees = [] } = useEmployees(
    // @ts-ignore
    +serviceRequestShown?.service?.organization?.id,
    {
      enabled: isRequestChosen,
      retry: 0,
    },
  )
  const { data: srNotes = [], refetch: refetchNotes } = useServiceRequestNotes(
    // @ts-ignore
    +serviceRequestShown?.id || +serviceRequest?.id,
    {
      enabled: isRequestChosen,
    },
  )

  // Service Request Update
  // * Assignee
  const { mutate: mutateServiceRequestAssignee } = useMutation(
    assignServiceRequest,
    {
      onSuccess: (data) => {
        setServiceRequestShown(data as any)
        if (getQueryKeyForServiceRequests && updateServiceRequestInCache) {
          queryClient.setQueryData<ServiceRequest[] | undefined>(
            getQueryKeyForServiceRequests(),
            (cache) => updateServiceRequestInCache(cache, data as any),
          )
        }
      },
    },
  )
  const handleAssignedEmployeeChange = useCallback(
    (id) => {
      mutateServiceRequestAssignee({
        employeeId: id,
        serviceRequestId: (serviceRequestShown as ServiceRequest).id,
        userId: userId,
      })
    },
    [serviceRequestShown],
  )

  // * Status
  const { mutate: mutateServiceRequestStatus, isError: isUpdateFailed } = useMutation(
    changeServiceRequestStatus,
    {
      onSuccess: (data) => {
        setServiceRequestShown(data)
        if (getQueryKeyForServiceRequests && updateServiceRequestInCache) {
          queryClient.setQueryData<ServiceRequest[] | undefined>(
            getQueryKeyForServiceRequests(),
            (cache) => updateServiceRequestInCache(cache, data),
          )
        }
      },
    },
  )
  const handleServiceRequestCancel = useCallback(() => {
    mutateServiceRequestStatus(
      {
        serviceRequestId: (serviceRequest as ServiceRequest).id,
        newStatus: ServiceRequestStatus.CANCELLED,
        userId: userId,
      },
      {
        onSettled: () => {
          onClose()
        },
      },
    )
  }, [serviceRequestShown])
  const handleSendConfirmationMessageForServiceRequest = useCallback(() => {
    mutateServiceRequestStatus(
      {
        serviceRequestId: serviceRequest?.id as string,
        newStatus: ServiceRequestStatus.PENDING,
        userId: userId,
      },
      {
        onSettled: () => {
          onClose()
        },
      },
    )
  }, [serviceRequestShown])
  const handleServiceRequestStatusChange = useCallback(
    (newStatus: ServiceRequestStatus) => {
      mutateServiceRequestStatus({
        serviceRequestId: serviceRequest?.id as string,
        newStatus: newStatus,
        userId: userId,
      })
    },
    [serviceRequest],
  )
  // * Notes
  const { mutate: mutateCommentAdded } = useMutation(addNote, {
    onSuccess: (data) => {
      if (getQueryKeyForNotes && addServiceRequestNoteInCache) {
        queryClient.setQueryData<ServiceRequestNote[] | undefined>(
          getQueryKeyForNotes(),
          (cache) => addServiceRequestNoteInCache(cache, data),
        )
      }
      refetchNotes()
    },
  })
  const handleCommentAdd = useCallback(
    (text: string, file?: File) => {
      mutateCommentAdded({
        serviceRequestId: +(serviceRequestShown as ServiceRequest).id,
        createdById: Number(userId),
        text: text,
        userId: userId,
        file: file as File,
      })
    },
    [serviceRequestShown],
  )

  // Shows Modal when all data received
  useEffect(() => {
    if (serviceRequestShown && allEmployees && srNotes && !isModalVisible) {
      setModalVisible(true)
    }
  }, [serviceRequestShown, allEmployees, srNotes, isModalVisible])

  const handleModalClose = useCallback(() => {
    handleHide()
    onClose()
  }, [handleHide])
  const component = useMemo(
    () => () => (
      <>
        {serviceRequestShown && isModalVisible && srNotes && allEmployees && (
          <ServiceRequestReviewModal
            serviceRequest={serviceRequestShown}
            serviceRequestNotes={srNotes}
            employees={allEmployees}
            disabled={!!isFetching}
            onCommentAdd={handleCommentAdd}
            onStatusChange={handleServiceRequestStatusChange}
            isUpdateFailed={isUpdateFailed}
            onEmployeeChange={handleAssignedEmployeeChange}
            onCancel={handleServiceRequestCancel}
            onConfirmationMessageSend={
              handleSendConfirmationMessageForServiceRequest
            }
            onClose={handleModalClose}
          />
        )}
      </>
    ),
    [
      isFetching,
      isModalVisible,
      serviceRequestShown,
      srNotes,
      allEmployees,
      handleCommentAdd,
      handleServiceRequestStatusChange,
      handleAssignedEmployeeChange,
      handleServiceRequestCancel,
      handleSendConfirmationMessageForServiceRequest,
      onClose,
    ],
  )

  return {
    ServiceRequestReviewModal: component,
    isVisible: isModalVisible,
    hide: handleHide,
  }
}
