import { IonIcon } from '@ionic/react'
import React, { ChangeEvent } from 'react'
import { attachOutline } from 'ionicons/icons'
import styles from '../styles.module.scss'

interface IProps {
  setFile: (file: File) => void
}

const AttachFile = ({ setFile }: IProps) => {
  const refFileInput = React.useRef<HTMLInputElement>(null)

  const handleClick = () => {
    refFileInput?.current?.click()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files?.[0]
    setFile(fileUploaded as File)
  }

  return (
    <>
      <IonIcon
        className={styles.noteIcon}
        icon={attachOutline}
        onClick={handleClick}
      />
      <input
        type='file'
        ref={refFileInput}
        onChange={handleChange}
        accept='image/png, image/jpeg, image/jpg, .pdf'
        style={{ display: 'none' }}
      />
    </>
  )
}

export default AttachFile
