import React from 'react'
import styles from './styles.module.scss'


const TermsAndConditions = (): JSX.Element => (
    <div className={styles.termsText}>
    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate
    totam sequi omnis. Molestiae qui facere provident magni voluptatum,
    laborum hic nobis placeat incidunt dolorum, perspiciatis quasi
    inventore, ipsum eius! Vero. Commodi voluptas dolores quo iure
    distinctio aliquam consequatur eius temporibus, eligendi dolorum
    rerum molestias amet tenetur soluta at alias vel sed velit,
    similique sint nemo asperiores! Libero, ipsa. Quasi, repudiandae!
    Nulla fugit, minus in doloremque culpa possimus suscipit natus
    voluptatibus excepturi a at ullam molestiae magnam nihil eos officia
    dignissimos libero eum fuga tempora aperiam nesciunt maxime quo
    harum. Ipsum! Earum, deserunt nulla? Temporibus tempora nobis
    aliquam minus illum. Minima pariatur alias fugit aliquid sint
    molestias nisi, eveniet facilis vero quod cumque maiores iure!
    Minima inventore accusamus et odit eveniet! Placeat et aspernatur
    aperiam inventore nam. Dolor amet sunt, quisquam magnam et
    repellendus porro provident itaque corrupti sapiente similique nemo
    beatae voluptas nam sint impedit harum. Neque autem corrupti velit!
    Atque, recusandae ipsam! Quo molestias natus magni nihil aliquam.
    Accusamus, a necessitatibus tempora blanditiis consequatur eos iure
    eius maiores facilis soluta cum magni, voluptate non alias
    architecto consectetur neque omnis. Consequatur temporibus sint sit
    itaque necessitatibus voluptatem eius porro corporis dolor, omnis
    voluptatibus tempore reprehenderit voluptate doloribus ipsa, fugit
    veritatis laborum commodi rem impedit libero modi neque minus?
    Repudiandae, autem. Doloribus, architecto neque omnis corrupti,
    fugit nemo voluptatum odit voluptate dolorem aliquid placeat
    obcaecati quas voluptas. Sapiente neque molestias praesentium
    deleniti expedita eaque quasi ex, nihil laudantium accusamus
    consequatur nisi. Excepturi laboriosam quaerat et totam? Culpa rem
    similique accusamus nisi cupiditate. Voluptate fugiat distinctio hic
    quisquam, necessitatibus quae dolor ratione ut assumenda iure atque
    quasi totam amet perspiciatis recusandae est! Recusandae illo
    eveniet corrupti modi unde quae ex ipsa, ullam iste exercitationem
    consequuntur quo nobis! Suscipit quibusdam natus in et, enim magnam
    mollitia quaerat soluta doloremque optio! Aliquam, iure. Est! Lorem
    ipsum dolor sit, amet consectetur adipisicing elit. Voluptatem,
    facilis? Placeat totam necessitatibus ex, veritatis maxime mollitia
    id nihil illum atque accusantium est. Quis ratione incidunt eius
    cumque eos velit! Corporis dicta ab soluta. Sequi velit
    necessitatibus, impedit explicabo saepe suscipit a perferendis
    quibusdam, recusandae tempore eligendi nostrum tempora nihil, ad ut
    corrupti obcaecati similique sapiente. Totam quas itaque dicta.
    Laudantium, architecto corrupti pariatur porro dignissimos iure
    assumenda provident, veniam quod iste debitis ut tenetur a,
    molestiae enim repudiandae rerum in quos veritatis inventore rem
    voluptatem error beatae. Sapiente, et.
  </div>
)

export default TermsAndConditions;