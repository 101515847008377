import { useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { AuthContext } from '../contexts/AuthContext'
import { BASE_API_URL } from './constants'
import { User } from '../shared/models/User'

const getUser = async ({ queryKey }: any) => {
  const [_key, { id, userId }] = queryKey
  return fetch(`${BASE_API_URL}/v1/users/${id}`, {
    method: 'GET',
    headers: {
      'userId': userId,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

export default function useUser(id: string): UseQueryResult<User, unknown> {
  const { userId } = useContext(AuthContext)
  return useQuery([`user-${id}`, { id, userId }], getUser, { refetchOnWindowFocus: false })
}
