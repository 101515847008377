import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
  useMemo,
  useContext,
} from 'react'
import { IonBackdrop, IonContent, IonPage } from '@ionic/react'
import { useMutation, useQueryClient } from 'react-query'
import { RouteProps } from 'react-router-dom'

import useUser from '../api/useUser'
import useUserSurveys from '../api/useUserSurveys'
import useAssignedServiceRequests, {
  updateAssignedServiceRequestInCache,
  getAssignedServiceRequestsQueryKey,
} from '../api/useAssignedServiceRequests'
import {
  getServiceRequestNotesQueryKey,
  addServiceRequestNoteInCache,
} from '../api/useServiceRequestNotes'

import Page from '../page/Page'
import { EmployeeSurveys } from './components/EmployeeSurveys'
import { EmployeeServiceRequests } from './components/EmployeeServiceRequests'
import { useNewServiceRequestModal } from '../shared/NewServiceRequest'
import { SuccessModal } from '../shared/SuccessModal'

import styles from './styles.module.scss'
import { UserInfo } from '../shared/UserInfo'
import { useEditUserModal } from '../shared/EditUserModal'
import { UserType, UserTypeFormatted } from '../shared/models/UserType'
import { ServiceRequest } from '../shared/models/ServiceRequest'
import { useServiceRequestReviewModal } from '../shared/ServiceRequestReviewModal'
import { Role } from './interfaces'
import { User } from '../shared/models/User'
import {
  promoteUserToAdmin,
  terminateUser,
  revokeAdmin,
  activateUser,
} from '../api/updateUserStatus'
import { AuthContext } from '../contexts/AuthContext'

const options: Role[] = [
  { value: 'admin', label: 'Admin' },
  { value: 'employee', label: 'Employee' },
]

const EmployeeProfile: FunctionComponent<RouteProps> = (props: RouteProps) => {
  // @ts-ignore
  const userId = props.match.params.id

  const queryClient = useQueryClient()
  const { userId: currentUserId } = useContext(AuthContext)

  const { data: employee, refetch: refetchEmployee } = useUser(userId)
  const [role, setRole] = useState<Role>()
  const [roleError, setRoleError] = useState('')
  const [statusError, setStatusError] = useState('')

  const { data: employeeSurveys } = useUserSurveys(userId)

  // Success Modal
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false)
  const handleSuccessModalClose = useCallback(() => {
    setSuccessModalVisible(false)
  }, [])

  // Employee Service Requests
  const { data: employeeServiceRequests, isLoading: isEmpServiceLoading, isError: isEmpServiceError } = useAssignedServiceRequests(userId, currentUserId)

  const { mutate: mutatePromote } = useMutation(promoteUserToAdmin, {
    onError: () => {
      setRoleError(
        `Failed to promote ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: mutateRevoke } = useMutation(revokeAdmin, {
    onError: () => {
      setRoleError(
        `Failed to update role of ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: terminateEmployee } = useMutation(terminateUser, {
    onSuccess: () => {
      refetchEmployee()
    },
    onError: () => {
      setStatusError(
        `Failed to terminate ${employee?.firstName}, please try again.`,
      )
    },
  })

  const { mutate: activateEmployee } = useMutation(activateUser, {
    onSuccess: () => {
      refetchEmployee()
    },
    onError: () => {
      setStatusError(
        `Failed to activate ${employee?.firstName}, please try again.`,
      )
    },
  })

  // Service Request Preview
  const [
    serviceRequestChosen,
    setServiceRequestChosen,
  ] = useState<ServiceRequest | null>(null)
  const handleServiceRequestPreviewClose = useCallback(() => {
    setServiceRequestChosen(null)
  }, [setServiceRequestChosen])

  const handleServiceRequestViewClick = useCallback(
    (id) => {
      setServiceRequestChosen(
        (employeeServiceRequests as ServiceRequest[]).find(
          (i) => i.id === id,
        ) || null,
      )
    },
    [setServiceRequestChosen, employeeServiceRequests],
  )
  const getBindedServiceRequestNotesQueryKey = useMemo(
    () =>
      getServiceRequestNotesQueryKey.bind(
        null,
        serviceRequestChosen?.id || NaN,
      ),
    [serviceRequestChosen?.id],
  )
  const getBindedAssignedServiceRequestsQueryKey = useMemo(
    () => getAssignedServiceRequestsQueryKey.bind(null, userId, currentUserId),
    [userId, currentUserId],
  )

  const { ServiceRequestReviewModal } = useServiceRequestReviewModal({
    serviceRequest: serviceRequestChosen,
    onClose: handleServiceRequestPreviewClose,

    getQueryKeyForNotes: getBindedServiceRequestNotesQueryKey,
    addServiceRequestNoteInCache: addServiceRequestNoteInCache,

    getQueryKeyForServiceRequests: getBindedAssignedServiceRequestsQueryKey,
    updateServiceRequestInCache: updateAssignedServiceRequestInCache,
  })

  // New Service Request
  const handleNewServiceRequestSuccess = useCallback(() => {
    setSuccessModalVisible(true)
  }, [])
  const {
    NewServiceRequest,
    show: showNewServiceRequest,
    isVisible: isNewServiceRequestPopupVisible,
  } = useNewServiceRequestModal({
    onSuccess: handleNewServiceRequestSuccess,
  })

  const handleServiceRequestView = useCallback((id) => {
    alert(`clicked at Service Request ${id}`)
  }, [])

  // Employee Surveys
  const [
    isAssignNewSurveyPopupVisible,
    setAssignNewSurveyPopupVisible,
  ] = useState(false)
  const handleAssignNewSurveyClick = useCallback(() => {
    setAssignNewSurveyPopupVisible(true)
  }, [])

  const handleSurveyView = useCallback((id) => {
    alert(`clicked at Survey ${id}`)
  }, [])

  /// Employee edit
  const [isEmployeeEditing, setIsEmployeeEditing] = useState(false)
  const handleEmployeeEditClick = useCallback(() => {
    setIsEmployeeEditing(true)
  }, [setIsEmployeeEditing])
  // * Cancel
  const handleUserEditCancel = useCallback(() => {
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  // * Success
  const handleUserEditSuccess = useCallback(() => {
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  // * Error
  const handleUserEditError = useCallback(() => {
    alert('Something went wrong during updating user data')
    setIsEmployeeEditing(false)
  }, [setIsEmployeeEditing])
  const { EditUserModal, isVisible: isEditUserModalVisible } = useEditUserModal(
    {
      userId: userId,
      onSuccess: handleUserEditSuccess,
      onCancel: handleUserEditCancel,
      onError: handleUserEditError,
    },
  )

  const setUserRole = useCallback(
    (role: Role) => {
      if (role.value === 'admin') {
        mutatePromote({ user: employee as User, userId })
      } else if (role.value === 'employee') {
        mutateRevoke({ user: employee as User, userId })
      }
      setRole(role)
      setRoleError('')
    },
    [mutatePromote, mutateRevoke, setRole, setRoleError, employee, userId],
  )

  const onUpdateStatus = useCallback(() => {
    setStatusError('')
    if (employee?.active) {
      terminateEmployee({ user: employee as User, userId });
    } else {
      activateEmployee({ user: employee as User, userId })
    }
  }, [setStatusError, terminateEmployee, activateEmployee, employee, userId])

  const areSomePopupOpen =
    isNewServiceRequestPopupVisible ||
    isAssignNewSurveyPopupVisible ||
    isSuccessModalVisible ||
    isEmployeeEditing

  return (
    <IonContent>
      {areSomePopupOpen && <IonBackdrop className={styles.backdrop} />}
      <Page name='Employee Profile'>
        <div className={styles.page}>
          <UserInfo
            userType={UserType.EMPLOYEE}
            user={employee ? employee : null}
            role={role as Role}
            setRole={setUserRole}
            options={options}
            onEdit={handleEmployeeEditClick}
            onUpdateStatus={onUpdateStatus}
            roleError={roleError}
            statusError={statusError}
          />
          <div className={styles.tablesWrapper}>
            <EmployeeServiceRequests
              serviceRequests={employeeServiceRequests}
              isEmpServiceLoading={isEmpServiceLoading}
              isEmpServiceError={isEmpServiceError}
              onNewServiceRequestClick={showNewServiceRequest}
              onServiceRequestView={handleServiceRequestViewClick}
            />
            {/* <div className={styles.employeeSurveysWrapper}>
              <EmployeeSurveys
                surveys={employeeSurveys}
                onAssignNewSurveyClick={() => {}}
                onSurveyView={handleSurveyView}
              />
            </div> */}
          </div>
        </div>
      </Page>
      <NewServiceRequest />
      {isSuccessModalVisible && (
        <SuccessModal
          onClose={handleSuccessModalClose}
          text='The request was created, someone from the CBO or Nonprofit should be in
        contact with the patient soon.'
        />
      )}
      {serviceRequestChosen && <ServiceRequestReviewModal />}
      {isEmployeeEditing && <EditUserModal />}
    </IonContent>
  )
}

export default EmployeeProfile
