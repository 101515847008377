// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_avatarStatusWrapper__19Y7X {\n  position: relative; }\n\n.styles_avatarStatusRing__GhQt9 {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 20px;\n  height: 20px;\n  background: white;\n  border-radius: 50%; }\n\n.styles_avatarStatus__xjtgd {\n  position: absolute;\n  bottom: 2px;\n  right: 2px;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  border: 3px solid #ffffff; }\n  .styles_avatarStatus_active__15M0E {\n    background-color: #5bd439; }\n  .styles_avatarStatus_away__1yjin {\n    background-color: #ffaf25; }\n  .styles_avatarStatus_busy__2rFSb {\n    background-color: #ff4747; }\n  .styles_avatarStatus_unknown__1imYp {\n    background-color: #aaaaaa; }\n", "",{"version":3,"sources":["webpack://src/shared/UserInfo/components/AvatarStatus/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,YAAY;EAEZ,iBAAiB;EACjB,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAElB,yBAAyB,EAAA;EAEzB;IACE,yBAAyB,EAAA;EAE3B;IACE,yBAAyB,EAAA;EAE3B;IACE,yBAAyB,EAAA;EAE3B;IACE,yBAAyB,EAAA","sourcesContent":[".avatarStatusWrapper {\n  position: relative;\n}\n\n.avatarStatusRing {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 20px;\n  height: 20px;\n\n  background: white;\n  border-radius: 50%;\n}\n\n.avatarStatus {\n  position: absolute;\n  bottom: 2px;\n  right: 2px;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n\n  border: 3px solid #ffffff;\n\n  &_active {\n    background-color: #5bd439;\n  }\n  &_away {\n    background-color: #ffaf25;\n  }\n  &_busy {\n    background-color: #ff4747;\n  }\n  &_unknown {\n    background-color: #aaaaaa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarStatusWrapper": "styles_avatarStatusWrapper__19Y7X",
	"avatarStatusRing": "styles_avatarStatusRing__GhQt9",
	"avatarStatus": "styles_avatarStatus__xjtgd",
	"avatarStatus_active": "styles_avatarStatus_active__15M0E",
	"avatarStatus_away": "styles_avatarStatus_away__1yjin",
	"avatarStatus_busy": "styles_avatarStatus_busy__2rFSb",
	"avatarStatus_unknown": "styles_avatarStatus_unknown__1imYp"
};
export default ___CSS_LOADER_EXPORT___;
