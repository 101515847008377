// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_verticalStepWithLine__2CFv_ {\n  position: relative; }\n  .styles_verticalStepWithLine__2CFv_::before {\n    content: ' ';\n    position: absolute;\n    top: 37px;\n    left: 22px;\n    width: 2px;\n    height: 100%;\n    background-color: #09395f; }\n\n.styles_verticalStepContent__2Kr-- {\n  position: relative;\n  top: -11px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: 46px;\n  height: 46px;\n  border-radius: 50%;\n  box-shadow: 0 0 0 2px #09395f;\n  background: white;\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n  line-height: 46px; }\n", "",{"version":3,"sources":["webpack://src/patient-list-upload-modal/components/VerticalStep/styles.module.scss"],"names":[],"mappings":"AAGA;EACE,kBAAkB,EAAA;EADpB;IAII,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,UAAU;IACV,YAAY;IACZ,yBAAyB,EAAA;;AAI7B;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EAEb,mBAAmB;EACnB,6BAA6B;EAE7B,WAAW;EACX,YAAY;EAEZ,kBAAkB;EAClB,6BAA6B;EAC7B,iBAAiB;EAEjB,+BAA+B;EAC/B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB,EAAA","sourcesContent":[".verticalStep {\n}\n\n.verticalStepWithLine {\n  position: relative;\n\n  &::before {\n    content: ' ';\n    position: absolute;\n    top: 37px;\n    left: 22px;\n    width: 2px;\n    height: 100%;\n    background-color: #09395f;\n  }\n}\n\n.verticalStepContent {\n  position: relative;\n  top: -11px;\n  display: flex;\n\n  align-items: center;\n  justify-content: space-around;\n\n  width: 46px;\n  height: 46px;\n\n  border-radius: 50%;\n  box-shadow: 0 0 0 2px #09395f;\n  background: white;\n\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: 900;\n  font-size: 16px;\n  line-height: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verticalStepWithLine": "styles_verticalStepWithLine__2CFv_",
	"verticalStepContent": "styles_verticalStepContent__2Kr--"
};
export default ___CSS_LOADER_EXPORT___;
