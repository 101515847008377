import React, { useState, useEffect, useMemo } from 'react'
import { IonSpinner, IonButton, IonIcon } from '@ionic/react'
import classNames from 'classnames'

import { AvatarStatus } from './components/AvatarStatus'

import { UserInfoProps } from './interfaces'

import styles from './styles.module.scss'
import { UserTypeFormatted } from '../models/UserType'

import { formatDisplayDate } from '../../shared/utils/formatTimestamp'
import useOrganization from '../../api/useOrganization'
import ProfileIcon from '../../profile/ProfileIcon'

import Select, { SingleValue } from 'react-select'

import { Role } from './interfaces'

import { ACTIVE, EMPLOYEE } from '../../api/constants'

import { getCurrentUser, getCognitoAttribute } from '../../authentication/auth';

const customStyles = {
  option: (provided: any, state: { isSelected: boolean }) => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isSelected ? '#eeedee' : '#fff',
  }),
  control: (provided: any) => ({ ...provided, width: 200 }),
}

export function UserInfo({
  userType,
  user,
  setRole,
  role,
  options,
  onEdit,
  onUpdateStatus,
  roleError,
  statusError,
}: UserInfoProps) {
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])

  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes);
    })
  }, [getCurrentUser])

  const currentUserId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:userId')
  }, [currentUserAttributes])

  const { data: organization } = useOrganization(user?.organizationIds[0])

  const formatRole = (): Role | null => {
    if (user?.userType) {
      return {
        value: user.userType.toLocaleLowerCase(),
        label: UserTypeFormatted[user.userType],
      }
    }
    return null
  }

  const setUserRole = (role: SingleValue<Role>) => setRole(role as Role)

  if (!user) {
    return (
      <div className={styles.section}>
        <h5
          className={classNames(
            styles.sectionTitle,
            styles.userInformationSectionTitle,
          )}
        >
          {UserTypeFormatted[userType]} Information
        </h5>
        <div className={styles.userIdentityWrapper}>
          <IonSpinner />
        </div>
        <div className={styles.userContacts}>
          <IonSpinner />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.section}>
      <div>
        <h5
          className={classNames(
            styles.sectionTitle,
            styles.userInformationSectionTitle,
          )}
        >
          {UserTypeFormatted[user.userType]} Information
        </h5>
        <div className={styles.userIdentityWrapper}>
          <AvatarStatus status={user.status === ACTIVE ? 'active' : 'unknown'}>
            <ProfileIcon
              size={'50'}
              userName={user.firstName + ' ' + user.lastName}
            />
          </AvatarStatus>
          <div className={styles.userIdentity}>
            <div className={styles.userName}>
              {user.firstName} {user.lastName}
            </div>
            <div className={styles.userType}>
              {UserTypeFormatted[user.userType]} User
            </div>
          </div>
          <div className={styles.editProfileWrapper}>
            <IonButton
              fill='clear'
              className={styles.editProfileButton}
              onClick={onEdit}
            >
              <IonIcon name='pencil' className={styles.editProfileIcon} />
              Edit Profile
            </IonButton>
          </div>
        </div>
        <div className={styles.userContacts}>
          {user?.organizationIds?.length === 1 && userType === 'EMPLOYEE' && (
            <div className={styles.userContactRow}>
              <span className={styles.userContactKey}>Organization:</span>
              <span className={styles.userContactValue}>
                {organization?.name}
              </span>
            </div>
          )}
          {(currentUserId != user.id && userType === EMPLOYEE) &&
            (<div className={styles.userRoleRow}>
              <span className={styles.userContactKey}>Role:</span>
              <span className={styles.userContactValue}>
                <Select
                  defaultValue={formatRole()}
                  value={role}
                  onChange={setUserRole}
                  options={options}
                  styles={customStyles}
                />
              </span>
            </div>)
          }
          {roleError && (
            <span className={styles.errorMessage}>{roleError}</span>
          )}
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Birthdate:</span>
            <span className={styles.userContactValue}>
              {formatDisplayDate(user.dateOfBirth as string)}
            </span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Email:</span>
            <span className={styles.userContactValue}>{user.email}</span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Phone #:</span>
            <span className={styles.userContactValue}>{user.phoneNumber}</span>
          </div>
          <div className={styles.spacer} />
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Address Line 1:</span>
            <span className={styles.userContactValue}>{user.addressLine1}</span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Address Line 2:</span>
            <span className={styles.userContactValue}>{user.addressLine2}</span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>City:</span>
            <span className={styles.userContactValue}>{user.city}</span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>State:</span>
            <span className={styles.userContactValue}>{user.state}</span>
          </div>
          <div className={styles.userContactRow}>
            <span className={styles.userContactKey}>Zipcode:</span>
            <span className={styles.userContactValue}>{user.zipCode}</span>
          </div>
        </div>
      </div>
      <div className={styles.userAction}>
        {(currentUserId != user.id && userType === EMPLOYEE) && (
          <span onClick={onUpdateStatus} className={styles.userTerminateButton}>
            {user.active ? 'Terminate Employee' : 'Activate Employee'}
          </span>
        )}
        {statusError && <p className={styles.errorMessage}>{statusError}</p>}
      </div>
    </div>
  )
}
