import React from 'react'
import {
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonText,
  IonImg,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import HealthOpXLogo from '../public/assets/HealthOpXLogo.png'

function VerifyAccount({ hideHeader }: { hideHeader?: boolean }) {
  return (
    <IonGrid>
      {!hideHeader && (
        <IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonBackButton
                text=''
                icon={arrowBack}
                defaultHref={'/login'}
                className='custom-back-button'
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonRow>
        <IonCol style={{ paddingRight: '100px' }}>
          {!hideHeader && (
            <>
              <IonRow>
                <IonCol>
                  <div className='ion-text-start'>
                    <IonImg className='logo' src={HealthOpXLogo} />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='ion-text-start'>
                  <IonText id='login-subtitle'>
                    Technology connecting healthcare to community.
                  </IonText>
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow style={{ paddingTop: '100px' }}>
            <IonCol className='ion-text-start'>
              <IonText id='login-title'>Verify Your Account</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              className='ion-text-start'
              style={{ paddingRight: '150px' }}
            >
              <IonText id='login-text'>
                Thank you for signing up with HealthOpX. Please verify your
                account through your email to proceed.
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className='ion-text-start ion-padding-end'>
            <IonCol className='ion-text-start ion-margin-horizontal'>
              <IonButton
                shape='round'
                fill='outline'
                expand='full'
                color='dark'
                routerLink='/login'
              >
                Go back to login screen
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default VerifyAccount
