import { useMemo } from 'react'

export default function useFilterColumns() {
    // Change the columns to { Name, Patient type, Services, Referred to, Referred from, Zip code }
    // Once the fields are added by BE
    return useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({
                    row: {
                        original: { id },
                    },
                }) => id,
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
                Cell: ({
                    row: {
                        original: { firstName },
                    },
                }) => firstName,
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                Cell: ({
                    row: {
                        original: { lastName },
                    },
                }) => lastName,
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({
                    row: {
                        original: { email },
                    },
                }) => email,
            },
            {
                Header: 'Phone Number',
                accessor: 'phoneNumber',
                Cell: ({
                    row: {
                        original: { phoneNumber },
                    },
                }) => phoneNumber,
            },
            {
                Header: 'Zip Code',
                accessor: 'zipCode',
                Cell: ({ row: { original: { zipCode } } }) => zipCode ? zipCode : '-'
            },
        ],
        [],
    )
}
