import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
} from '@ionic/react'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { mail } from 'ionicons/icons'
import { useForgotPassword } from '../../api/useResetPassword'

type AuthenticationError = {
  code: string
  message: string
  name: string
}

function EnterEmail({
  next,
  setEmail,
}: {
  setEmail: React.Dispatch<React.SetStateAction<string>>
  next: (isSlide: boolean) => void
}): JSX.Element {
  const [error, setError] = useState<null | AuthenticationError>(null)
  const formMethods = useForm({ defaultValues: { email: '' } })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods

  const { mutate: forgotPassword, isLoading } = useForgotPassword({
    onSuccess: (res) => {
      // success
      next(true)
    },
    onError: (err: Error) => {
      // error
      setError(err as AuthenticationError)
    },
  })

  const onSubmit = (data: any) => {
    forgotPassword(data.email)
    setEmail(data.email)
  }

  return (
    <IonGrid>
      <IonRow style={{ marginTop: 50 }}>
        <IonCol className='ion-text-start'>
          <IonText id='login-title'>Reset your password</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className='ion-text-start'>
          <IonText id='login-text'>Enter your email addres to reset your password</IonText>
        </IonCol>
      </IonRow>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <IonList>
            <IonItem>
              {errors.email ? (
                <IonLabel position='floating' color='danger'>
                  {errors.email.message}
                </IonLabel>
              ) : (
                <IonLabel position='stacked'>Email</IonLabel>
              )}
              <IonInput
                {...register('email', {
                  required: 'Email is required.',
                })}
                placeholder='user@email.com'
                type='email'
                name='email'
              />
              <IonIcon style={{ height: '100%' }} icon={mail} slot='end' />
            </IonItem>
          </IonList>
          {error && (
            <IonText color='danger' className='ion-text-center'>
              {error?.message}
            </IonText>
          )}
          <IonButton
            className='ion-margin-horizontal ion-margin-top'
            shape='round'
            fill='outline'
            expand='full'
            color='dark'
            type='submit'
          >
            {isLoading ? <IonSpinner name='crescent' color='medium' /> : 'Next'}
          </IonButton>
        </form>
      </FormProvider>
    </IonGrid>
  )
}

export default EnterEmail
