import React, { useState, useEffect, useMemo } from 'react'
import { IonIcon, IonLabel, IonItem } from '@ionic/react'
import EmployeeProfile from '../employee/EmployeeProfile'
import { RouteComponentProps, withRouter } from 'react-router'
import { RouteProps, appRoutes, otherRoutes } from '../main/SideMenuRoutes'
import HealthOpXLogo from '../public/HealthOpXLogo.svg'
import './styles/sideMenu.css'
import './styles/sideMenu.scss'
import {
  getCurrentUser,
  getCognitoAttribute,
  signOut,
} from '../authentication/auth'
import { personOutline, menuOutline, closeOutline, powerOutline } from 'ionicons/icons'

import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  MenuItem,
  Menu,
} from 'react-pro-sidebar'

function SideMenu({ history }: RouteComponentProps): JSX.Element {
  const [isShow, setShow] = useState(false)
  const [currentUserAttributes, setCurrentUserAttributes] = useState([])
  const [activePage, setActivePage] = useState<string | null>(null)
  useEffect(() => {
    getCurrentUser((attributes: any) => {
      setCurrentUserAttributes(attributes)
    })
  }, [getCurrentUser])

  const loggedInUserId = useMemo(() => {
    return getCognitoAttribute(currentUserAttributes, 'custom:userId')
  }, [currentUserAttributes])

  const signOutHandler = () => {
    signOut()
  }

  const navigateToPage = (route: RouteProps): void => {
    if (!Array.isArray(route.path)) history.push(route.path)
    else {
      route.path.forEach((pth) => {
        history.push(pth)
      })
    }
    setActivePage(route.title)
  }

  const updatedRoutes = useMemo(() => {
    return [
      {
        title: 'Profile',
        path: '/employees/' + loggedInUserId,
        icon: personOutline,
        component: EmployeeProfile,
      },
      ...appRoutes,
    ]
  }, [loggedInUserId, appRoutes])

  const toggleSidebar = () => setShow(!isShow)

  const renderAppRoutes = (): JSX.Element[] => {
    return updatedRoutes.map((route: RouteProps) => {
      return (
        <MenuItem
          className={route.title === activePage ? 'activeMenuItem' : 'menuItem'}
          icon={<IonIcon icon={route.icon} color='dark' />}
          title={route.title}
          onClick={(): void => navigateToPage(route)}
        />
      )
    })
  }

  const renderLogoutLink = (): JSX.Element => {
    return (
      <Menu>
        <MenuItem
          title='Logout'
          icon={<IonIcon icon={powerOutline} color='dark' />}
          onClick={signOutHandler}
        />
      </Menu>
    )
  }

  return (
    <ProSidebar collapsed={!isShow} onToggle={toggleSidebar}>
      <SidebarHeader title='Menu'>
        {!isShow ? (
          <IonIcon
            icon={menuOutline}
            size='large'
            color='dark'
            onClick={toggleSidebar}
          />
        ) : (
          <div>
            <span onClick={toggleSidebar}>
              <IonIcon icon={closeOutline} size='large' color='dark' />
            </span>
            <img src={HealthOpXLogo} alt='HealthOpx' onClick={toggleSidebar} />
          </div>
        )}
      </SidebarHeader>
      <SidebarContent>
        <Menu>{renderAppRoutes()}</Menu>
      </SidebarContent>
      <SidebarFooter>{renderLogoutLink()}</SidebarFooter>
    </ProSidebar>
  )
}

export default withRouter(SideMenu)
